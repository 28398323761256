import { styled, tooltipClasses, TooltipProps, Tooltip } from "@mui/material";
import pxToRem from "../../helpers/pxToRem";
import styles from '../index.module.scss';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: theme.palette.common.white,
        backgroundColor: styles.neutrals50,
        borderRadius: pxToRem(8),
        border: 0,
        
        color: styles.shades100,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontSize: 11,
        padding: pxToRem(24),
    },
  }));