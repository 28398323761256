import { FormControl, styled } from "@mui/material";
import styles from '../index.module.scss';
import pxToRem from "../../helpers/pxToRem";


export const TeacherRegFormControl = styled(FormControl)({
    "& label.Mui-focused": {
        color:  styles.primaryYellow500,
        // color:'#00000099'
    },
    "&:hover label": {
        // color:  styles.primaryYellow500,
    },
    "& .MuiInputBase-root": {
        "&::after": {
            borderBottom: `2px solid ${styles.primaryYellow500}`
        },
        "&::before": {
            // borderBottom: `1px solid ${styles.neutrals400}`
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${styles.primaryYellow500}`,
        },
        '&.Mui-focused:after': {
            borderBottom: `${pxToRem(2)} solid ${styles.primaryYellow500}`,
        },
    }
})

export const TeacherProfileFormControl = styled(FormControl)({
        "& label.Mui-focused": {
        color:  styles.secondaryBlue800,
        // color:'#00000099'
    },
    "&:hover label": {
        // color:  styles.primaryYellow500,
    },
    "& .MuiInputBase-root": {
        "&::after": {
            borderBottom: `2px solid ${styles.secondaryBlue800}`
        },
        "&::before": {
            // borderBottom: `1px solid ${styles.neutrals400}`
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${styles.secondaryBlue800}`,
        },
        '&.Mui-focused:after': {
            borderBottom: `${pxToRem(2)} solid ${styles.secondaryBlue800}`,
        },
    }
})

export const SchoolRegFormControl = styled(FormControl)({
    "& label.Mui-focused": {
        color:  styles.primaryPurple400,
        // color:'#00000099'
    },
    "&:hover label": {
        // color:  styles.primaryYellow500,
    },
    "& .MuiInputBase-root": {
        "&::after": {
            borderBottom: `2px solid ${styles.primaryPurple400}`
        },
        "&::before": {
            // borderBottom: `1px solid ${styles.neutrals400}`
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${styles.primaryPurple400}`,
        },
        '&.Mui-focused:after': {
            borderBottom: `${pxToRem(2)} solid ${styles.primaryPurple400}`,
        },
    },
})

export const SchoolMainFormControl = styled(FormControl)({
    "& label.Mui-focused": {
        color:  styles.secondaryBlue800,
        // color:'#00000099'
    },
    "&:hover label": {
        // color:  styles.primaryYellow500,
    },
    "& .MuiInputBase-root": {
        "&::after": {
            borderBottom: `2px solid ${styles.secondaryBlue800}`
        },
        "&::before": {
            // borderBottom: `1px solid ${styles.neutrals400}`
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${styles.secondaryBlue800}`,
        },
        '&.Mui-focused:after': {
            borderBottom: `${pxToRem(2)} solid ${styles.secondaryBlue800}`,
        },
    }
})

export const SchoolNameFormControl = styled(FormControl)({
    boxShadow: 'none', 
    '.MuiOutlinedInput-notchedOutline': { border: 0 } ,
    '.MuiInputBase-input': {padding: 0 }// `${pxToRem(0)} ${pxToRem(10)}`},


    // "& .MuiInputBase-root": {
    //     "&::after": {
    //         borderBottom: 'none'
    //     },
    //     "&::before": {
    //         // borderBottom: `1px solid ${styles.neutrals400}`
    //     },
    //     '&:hover:not(.Mui-disabled, .Mui-error):before': {
    //         borderBottom: 'none'
    //     },
    //     '&.Mui-focused:after': {
    //         borderBottom: 'none'
    //     },
    // }
})