

import { InputLabel, Select, MenuItem, Stack } from "@mui/material";
import moment from "moment";
import { useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import pxToRem from "../../../../helpers/pxToRem";
import { RootState } from "../../../../redux/store";
import styles from "../index.module.scss";
import { RegModalWrapper } from "../ModalContent";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getIncompleteState } from "../../ReviewPage/helper";
import { TeacherRegFormControl } from "../../../../components/FormControl";
import { v4 as uuidv4 } from "uuid";
import { NSWAccreditationLevel } from "../../../../firebase/types-teacher";
import FileUpload, { MultiFileType } from "../../../../components/FileUpload";
import { getServiceDocs, handleServiceDocsChange } from "./helper";
import { setErrorMsg } from "../../../../redux/uiSlice";
import { RegMultipleFiles } from "../../../../components/FileUpload/RegUploadComponents";
dayjs.extend(customParseFormat);

// = ["Australian Citizen", "Permanent Resident", "Temporary Working Visa"];
const AccreditationLevel = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.user);
    const userId = useSelector((state: RootState) => state.user.userId);
    const reg = useSelector((state: RootState) => state.userReg.fields);

    const [selected, setSelected] = useState<NSWAccreditationLevel | undefined>(reg.service?.userLevelNomination);
    // const reference = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Document reference") }, defaultValue: reg.workingRights?.reference || ""});   
    // const [expiryDate, setExpiryDate] = useState<Dayjs|null>(reg.workingRights?.expiry?dayjs(reg.workingRights?.expiry, "DD-MM-YYYY"):null); 
    // const [docUrl, setDocUrl] = useState<{ id: string, path: string, file: any, fileType?: string }[]>(Object.entries(reg.service?.urls || {}).map(([id, el]) => { return { id: id, path: getUserDocStoragePath(userId || '', `serviceStatement-${id}`), file: typeof el === 'string' ? el : el.value, fileType: typeof el === 'string' ? undefined : el.type } }));
    const newId = useRef(uuidv4());

    const [files, setFiles] = useState<MultiFileType[]>(getServiceDocs(reg.service?.urls));
    const onFileChange = (file: File) => {
        setFiles(prev => [...prev, { id: uuidv4(), file: file, fileName: file.name }]);
    }
    const handleFileRemove = (id: string) => {
        setFiles(prev => prev.filter(el => el.id !== id));
    }

    const isAllComplete = (): boolean => {
        return selected !== undefined && ([NSWAccreditationLevel.BAND1, NSWAccreditationLevel.UNSURE].includes(selected) || files.length > 0);
    }
    const isSomeComplete = (): boolean => {
        return selected !== undefined
    }

    const handleUpdate = async () => {
        try {
            if (!userId) { throw new Error("User is not authenticated. Please refresh and try again.") };
            let updateData: UserUpdate = {};

            if (selected === NSWAccreditationLevel.BAND1) {
                updateData = {
                    teacher: {
                        path: `/service/${reg.service?.serviceId || newId.current}`,
                        data: {
                            userLevelNomination: selected,
                            userLocation: isSomeComplete() ? reg.homeAddress?.homeState || 'NSW' : null,
                            timeUploaded: moment.tz(moment(), "Australia/Melbourne").unix(),
                            uploadedBy: userId,
                        }
                    }
                }
            } else if (files.length > 0) {

                const data = await handleServiceDocsChange(userId, reg.super?.docs, files)

                updateData = {
                    teacher: {
                        path: `/service/${reg.service?.serviceId || newId.current}`,
                        data: {
                            userLevelNomination: selected,
                            userLocation: isSomeComplete() ? reg.setLocation?.homeState || 'NSW' : null,
                            timeUploaded: moment.tz(moment(), "Australia/Melbourne").unix(),
                            uploadedBy: userId,
                            urls: data
                        }
                    }
                }
            } else {
                updateData = { teacher: { path: `/`, data: { service: null } } }
            }
            dispatch(updateFields(updateData));
        } catch (error: any) {
            dispatch(setErrorMsg(error.message));
        }
    }

    const renderText = useMemo(() => {
        switch (selected) {
            case NSWAccreditationLevel.BAND1: return <>I have Provisional teaching accreditation from NESA.</>
            case NSWAccreditationLevel.BAND2: return <>I am an accredited teacher who has:<br />a{')'} Proficient Accreditation, and <br />b{')'} Completed 2 years {'('}406 days{')'} of full-time equivalent {'('}FTE{')'} experience.</>
            case NSWAccreditationLevel.BAND3: return <>I am an accredited teacher who has:<br />a{')'} Proficient Accreditation, and <br />b{')'} Completed more than 2 years {'('}406 days{')'} of full-time equivalent {'('}FTE{')'} experience at the Proficient level.</>
            case NSWAccreditationLevel.UNSURE: return <>I am unsure which band I belong to and would like some guidance on the requirements and qualifications needed to determine my appropriate level as a teacher.</>
            default: return <></>
        }
    }, [selected])

    const getLabel = (level: NSWAccreditationLevel) => {
        switch (level) {
            case NSWAccreditationLevel.BAND1: return "Band 1 (Provisional Teacher)"
            case NSWAccreditationLevel.BAND2: return "Band 2 (Proficient Teacher - more than 2 years FTE)"
            case NSWAccreditationLevel.BAND3: return "Band 3 (Proficient Teacher - more than 2 years FTE at the proficient level)"
            case NSWAccreditationLevel.UNSURE: return "I'm Unsure"
            default: return <></>
        }
    }

    return (
        <>
            <RegModalWrapper title="Accreditation Level" handleUpdate={handleUpdate}
                state={getIncompleteState({
                    workingRights: reg.workingRights
                })} isAllComplete={isAllComplete()}
            >
                <Stack gap={pxToRem(40)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >Accreditation Level</h5>
                        <p className={styles.p}>To ensure you are paid correctly, nominate the band based on your years of experience:</p>
                    </Stack>
                    <Stack gap={pxToRem(24)}>
                        <TeacherRegFormControl variant="standard" >
                            <InputLabel>Casual Teacher Band</InputLabel>
                            <Select value={selected} onChange={(e) => setSelected(e.target.value as NSWAccreditationLevel)}
                                MenuProps={{
                                    PaperProps: { style: { overflowX: 'scroll' } }
                                }}
                            >
                                {
                                    Object.values(NSWAccreditationLevel).sort((a, b) => a.localeCompare(b)).map((el, i) => (
                                        <MenuItem key={i} value={el}>{getLabel(el)}</MenuItem>
                                    ))
                                }
                            </Select>
                        </TeacherRegFormControl>
                        <Stack className={styles.accreditationLevelBanner} sx={{ opacity: selected !== undefined ? 1 : 0, transition: 'all 300ms' }}>
                            <small> {renderText} </small>
                        </Stack>
                        {
                            selected !== undefined &&
                            <>
                                <small>{`Upload statement of service ${[NSWAccreditationLevel.BAND1, NSWAccreditationLevel.UNSURE].includes(selected) ? '(Optional)' : '(Required)'}`}</small>
                                <FileUpload onChangeCallback={onFileChange} multiple extensions={'document'}/>
                                <RegMultipleFiles files={files} removeFile={handleFileRemove} />
                            </>
                        }
                    </Stack>
                </Stack>
            </RegModalWrapper>
        </>
    );
};

export default AccreditationLevel;