import { Suspense, useEffect, useMemo } from "react";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.scss";
import "./shared/unScopedStyles.scss";
import ErrorBoundaries from "./components/ErrorFallback/ErrorFallback";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import { ROOT_ROUTERS } from "./routers/rootRouters";
import "./firebase/configs";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import urlimage from "./assets/images/header/urlimage.png";
import {
  deleteFromShopCart,
  setAccountState,
  setEmailVerified,
  setLoginStatusIsLoading,
  setUser,
  setUserEmpty,
  setUserId,
  setUserLogin,
  setUserLogout,
  setUserType,
} from "./redux/userSlice";
import { getDatabase, onValue, ref } from "firebase/database";
import { Stack } from "@mui/system";
import { RootState } from "./redux/store";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import useGetScreenType from "./hooks/useGetScreenType";
import { setErrorMsg, setScreenType } from "./redux/uiSlice";
import titles_routers from "./routers/pageTitles";
import ReactGA from "react-ga4";
import descriptions_routers from "./routers/pageDescriptions";
import DocumentMeta from "react-document-meta";
import ScrollToTopListenerComponent from "./components/ScrollToTopListenerComponent";
import { StyledEngineProvider } from "@mui/material/styles";
// @ts-ignore;
import LexendDecsFonts from "./assets/fonts/LexendDeca-VariableFont_wght.ttf";
import CssBaseline from "@mui/material/CssBaseline";
import { batchRequests } from "./firebase/helpers";
import { Product } from "./firebase/types";
import { getMerchById, updateUserTimestamp } from "./firebase/general-apis";
import { toUserType } from "./redux/types";
import { TabNames } from "./routers/type";
import UserAlert from "./components/AlertComponent/UserAlert";
import { TeacherAccountState } from "./firebase/types-teacher";

const theme = createTheme({
  typography: {
    fontFamily: "Lexend Deca",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Lexend Deca';
        src: url(${LexendDecsFonts})
      }
      `,
    },
  },
});

const TRACKING_ID = "G-LK9Q25DC1X";
ReactGA.initialize(TRACKING_ID);

function App() {
  const shopCart = useSelector((state: RootState) => state.user.shopCart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const screenType = useGetScreenType();
  const fbIdsInStorage = useMemo(() => {
    const storageFbIds = shopCart.map((cartItem) => cartItem.fbId);
    return Array.from(new Set(storageFbIds));
  }, [shopCart]);

  const userDelete = useSelector((state: RootState) => state.userReg.userDelete);
  const errorMsg = useSelector((state: RootState) => state.ui.errorMsg);

  const userId = useSelector((state: RootState) => state.user.userId);
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, [location]);

  useEffect(() => {
    if(user && userId){
      updateUserTimestamp(userId);
    }
  }, [location, user, userId]);

  useEffect(() => {
    dispatch(setScreenType(screenType));
  }, [screenType]);

  const meta = useMemo(() => {
    let title = "Spark Relief Teachers";
    let description = "Connecting Casual Relief Teachers and Schools throughout Victoria";

    let meta = {
      property: {
          'og:title': 'Spark Relief Teachers',
          'og:description': 'Connecting Casual Relief Teachers and Schools throughout Victoria.',
          'og:image': urlimage,
          'og:image:width': '1200',
          'og:image:height': '630',
          'og:type': 'website',
          'og:url': 'https://www.sparkreliefteachers.com/',
      }
  }


  console.log(`Rouer Title = ${location.pathname}`)
    if (titles_routers[location.pathname]) {
      title = titles_routers[location.pathname];
    }

    if (descriptions_routers[location.pathname]) {
      description = descriptions_routers[location.pathname];
    }

    return {
      description: description,
      title: title,
    };
  }, [location]);

  const updateSignInStatus = () =>
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (user.emailVerified) {
          console.log("Email is Verified");
          dispatch(setEmailVerified(true));
        } else {
          console.log("Email is NOT verified!!");
          dispatch(setEmailVerified(false));
        }

        dispatch(setEmailVerified(true));
        console.log("User is logged in");
        dispatch(setUserLogin(true));
        const db = getDatabase();
        dispatch(setUserId(user.uid));

        const reference = ref(db, `users/${user.uid}`);
        const accountReference = ref(db, `teachers/${user.uid}`);

        onValue(reference, (snapshot) => {
          let userData;
          if(snapshot.exists()) {
            dispatch(setUserType(snapshot.val()))
            userData = toUserType(snapshot.val());
            dispatch(setUser(userData));
          } else {
            dispatch(setUser(null));
            dispatch(setUserType(null));
            dispatch(setUserEmpty(true));
          }

          // if (!user.emailVerified) {
          //   navigate(`/${TabNames.VERIFY_EMAIL}`);
          //   return;
          // }
          // if(!userData?.accountType) {
          //   navigate(`${TabNames.CHOOSE_ACC_TYPE}`);
          //   return;
          // }
        });
        onValue(accountReference, (snapshot) => {
          if(snapshot.exists()) {
            const accountState = (snapshot.val()?.accountState?.value || TeacherAccountState.INCOMPLETE) as TeacherAccountState;  
            dispatch(setAccountState(accountState));
          }     
        });
      } else {
        // Wait on redirect 
        console.log("User is NOT logged in");
        if(userDelete !== undefined && userDelete === false) {
          console.log("Restting data");
          dispatch(setUserLogout());
        }
      }
      dispatch(setLoginStatusIsLoading(false));
    });

  // Delete products in shopcart storage that are already deleted in website
  const validateShopcartStorage = async () => {
    const res = await batchRequests<Product | undefined>({
      asyncFuncs: fbIdsInStorage.map((fbId) => () => getMerchById(fbId)),
      count: 20,
      errorMsg: "validate local storage of shop cart items.",
    });
    if (res.code === 200) {
      const merchandiseExists = (
        (res.value as (Product | undefined)[]).filter((r) => r) as Product[]
      ).reduce<{
        [fbId: string]: boolean;
      }>((acc, cur) => {
        acc[cur.id] = true;
        return acc;
      }, {});
      shopCart.map((item) => {
        if (!merchandiseExists[item.fbId]) {
          dispatch(deleteFromShopCart(item));
        }
      });
    }
  };

  useEffect(() => {
    const unsubscribeAuthStateChange = updateSignInStatus();
    return () => unsubscribeAuthStateChange();
  }, [userDelete]);

  useEffect(() => {
    validateShopcartStorage();
  }, [fbIdsInStorage]);

  return (
    <StyledEngineProvider injectFirst>
      <div
        className='App'
        style={{ position: "relative", overflow: "hidden" }}
      >
        <ErrorBoundaries>
          <DocumentMeta {...meta} />
          <ThemeProvider theme={theme}>
            {/* <SessionTimeout/> */}
            <CssBaseline />
            <UserAlert open={errorMsg !== undefined} content={errorMsg || "An error has occurred."} handleClose={() => dispatch(setErrorMsg(undefined))} />
            <Suspense
              fallback={
                <Stack sx={{ height: "100vh" }} justifyContent={"center"}>
                  <LoadingSpinner />
                </Stack>
              }
            >
              <ScrollToTopListenerComponent />
              <Routes>
                {Object.values(ROOT_ROUTERS).map((rootRouter) => rootRouter)}
              </Routes>
            </Suspense>
          </ThemeProvider>
        </ErrorBoundaries>
      </div>
    </StyledEngineProvider>
  );
}

export default App;