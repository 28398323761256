import { InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { RegModalWrapper } from "../ModalContent";
import pxToRem from "../../../../helpers/pxToRem";
import styles from "../index.module.scss";
import { UserRegTextField } from "../../../../components/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import useTextInput from "../../../../hooks/useTextInput";
import { getErrorMsgForTextInput } from "../../../../helpers/inputValidators";
import moment from "moment";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { LightTooltip } from "../../../../components/Tooltip";
import { getIncompleteState } from "../../ReviewPage/helper";
import { DateValidationError } from "@mui/x-date-pickers/models";
import { CustomizedDatePicker } from "../../../../components/DatePicker";
import { AccreditationDocument, AccreditationDocumentDetails, AccreditationRequiredField, TeacherEmploymentSetting } from "../../../../redux/types";
import { ECCQualificationLevels, ECCQualToTitle, FieldState, TeacherAccountState } from "../../../../firebase/types-teacher";
import { uploadAccreditationDocument } from "../../../../firebase/teacherApi";
import { v4 as uuidv4 } from "uuid";
import FileUpload, { FileType } from "../../../../components/FileUpload";
import { documentUploadHandler, requiredDateHandler, WORKING_RIGHTS } from "./helper";
import { setErrorMsg } from "../../../../redux/uiSlice";
import { RegFileUploadLabel } from "../../../../components/FileUpload/RegUploadComponents";
import { TeacherRegFormControl } from "../../../../components/FormControl";


const getImageUrl = (accred?: any, expired?: boolean): FileType | undefined => {
    if (expired) return undefined;
    let imageUrl = accred?.imageURL;
    if (imageUrl) {
        return { file: imageUrl, fileName: accred?.docTitle }
    } else {
        return undefined
    }
}

interface ITeachingDocumentation {
    title: string;
    details: AccreditationDocumentDetails;
    employmentSetting?: TeacherEmploymentSetting;
    // qualificationOptions?: {label: string, value: string}[];
}
const TeachingDocumentation = ({ title, details, employmentSetting }: ITeachingDocumentation) => {
    const dispatch = useDispatch();
    const reg = useSelector((state: RootState) => state.userReg.fields);
    const userId = useSelector((state: RootState) => state.user.userId);
    const accountState = useSelector((state: RootState) => state.user.accountState);

    const document = useMemo(() => reg.accreditation?.[`${employmentSetting}`]?.[`${reg.setLocation?.homeState}`]?.[`${details.type}`]
        , [reg.setLocation?.homeState, employmentSetting, details.type, reg.accreditation?.[`${employmentSetting}`]?.[`${reg.setLocation?.homeState}`]?.[`${details.type}`]])

    const expired = useMemo(() => {
        let expiryDate = document?.expiryDate
        return expiryDate ?
            moment(expiryDate, "DD-MM-YYYY").isBefore(moment())
            : false
    }, [reg.setLocation?.homeState, reg.setLocation?.homeState, document?.expiryDate]);

    const notIncompleteExpired = useMemo(() => {
        return expired && accountState !== TeacherAccountState.INCOMPLETE
    }, [expired, accountState])

    const [expiryDate, setExpiryDate] = useState<Dayjs | null>(expired ? null : getRequiredDate(document, details.required_datefield));
    const reference = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, `${details.short_title} reference`) }, defaultValue: notIncompleteExpired ? "" : document?.reference || "" });
    const [qualification, setQualification] = useState<string|null>(document?.qualification || null);

    // const [skip, setSkip] = useState<boolean|undefined>(document?.userSkipped);

    const [file, setFile] = useState<FileType | undefined>(getImageUrl(document, notIncompleteExpired));
    const onFileChange = (file: File) => {
        setFile({ file: file, fileName: file.name });
    }

    const docId = useRef(uuidv4());

    const [dateError, setDateError] = useState<DateValidationError | null>(null);
    const errorMessage = useMemo(() => {
        switch (dateError) {
            case 'minDate': {
                return 'Please select a date after today'
            }
            case 'maxDate': {
                return 'Please select a date before today'
            }
        }
    }, [dateError]);

    const isAllComplete = () => {
        // return (details.optional && skip) || ((details.required_datefield === null || (!dateError && expiryDate?.isValid())) && reference.value.trim() !== "" && !reference.hasError && file !== undefined )
        return (details.required_datefield === null || (!dateError && expiryDate?.isValid())) && reference.value.trim() !== "" && !reference.hasError && file !== undefined &&
            (details.type !== 'earlyChildcareQualification' || qualification !== null)
    }
    const isSomeComplete = () => {
        return Boolean(!dateError && expiryDate?.isValid()) || Boolean(reference.value) || Boolean(file) || (details.type === 'earlyChildcareQualification' && qualification !== null)
    }

    const handleUpdate = async () => {
        try {
            if (!userId) { throw new Error("User is not authenticated. Please refresh and try again.") };
            let updateData: UserUpdate = {};
            // let formattedExpiry = '';
            if (details.required_datefield !== null && (dateError)) { throw new Error("Date is not valid. Please try again.") };
            // if (expiryDate && expiryDate.isValid()) { formattedExpiry = expiryDate.format("DD-MM-YYYY"); }

            const { fileUrl, fileType, docTitle } = await documentUploadHandler(userId, file, {
                url: document?.imageURL,
                type: document?.type,
                docTitle: document?.docTitle
            })

            const datesData = requiredDateHandler(document, expiryDate, details.required_datefield, details.renewal_frequency_years);

            if (document && document.id) {
                // Update data
                updateData = {
                    teacher: {
                        path: `/accreditation/${document.id}`,
                        data: {
                            ...datesData,
                            ...(document.reference ?
                                document.reference !== reference.value ? { reference: !reference.hasError && reference.value !== "" ? reference.value : null } : null
                                :
                                { reference: !reference.hasError && reference.value !== "" ? reference.value : null }
                            ),
                            userSkipped: null,
                            ...(document.accreditationType ? null : { accreditationType: `${details.type}` }),
                            imageURL: fileUrl, type: fileType, docTitle: docTitle,
                            ...(isAllComplete() ? { state: 'submitted', dateSubmitted: moment().format("DD-MM-YYYY") } : isSomeComplete() ? { state: 'incomplete' } : null),

                            ...(details.type === 'earlyChildcareQualification' ? 
                                {qualification: qualification}
                                : null
                            )
                        }
                    }
                }
            } else {
                // Create new document
                if (isSomeComplete()) {
                    console.log(reference.value, reference.value !== "");
                    updateData = {
                        teacher: {
                            path: `/accreditation`,
                            data: {
                                [`${docId.current}`]: {
                                    accreditationType: `${details.type}`,
                                    ...datesData,
                                    dateSubmitted: moment().format("DD-MM-YYYY"),
                                    imageURL: fileUrl, type: fileType, docTitle: docTitle,
                                    reference: !reference.hasError && reference.value !== "" ? reference.value : null,
                                    userSkipped: null,
                                    ...(isAllComplete() ? { state: 'submitted' } : isSomeComplete() ? { state: 'incomplete' } : null),

                                    ...(details.type === 'earlyChildcareQualification' ? 
                                        {qualification: qualification}
                                        : null
                                    )
                                }
                            }
                        }
                    }
                }
            }

            dispatch(updateFields(updateData));
        } catch (error: any) {
            dispatch(setErrorMsg(error.message));
        }
    }

    const handleUpdateRequest = async () => {
        try {
            if (!userId) { throw new Error("User is not authenticated. Please refresh and try again.") };
            // let formattedExpiry = '';
            // if (expiryDate && !dateError && expiryDate.isValid()) { formattedExpiry = expiryDate.format("DD-MM-YYYY"); }
            if (!expiryDate || dateError) { throw new Error("Date is not valid. Please try again.") };

            const datesData = requiredDateHandler(document, expiryDate, details.required_datefield, details.renewal_frequency_years);
            const { fileUrl, fileType, docTitle } = await documentUploadHandler(userId, file)

            const doc: AccreditationDocument = {
                id: uuidv4(),
                ...datesData,
                // expiryDate: formattedExpiry,
                accreditationType: details.type,
                dateSubmitted: moment().format("DD-MM-YYYY"),
                reference: reference.value,
                imageURL: fileUrl, type: fileType, docTitle: docTitle,
                state: 'submitted',
                ...(details.type === 'earlyChildcareQualification' ? 
                    {qualification: qualification}
                    : null
                )
            }
            const res = await uploadAccreditationDocument(userId, doc);
            if (res.code !== 200) {
                throw new Error("There was an error creating a new request!");
            } else {
                console.log("Successfully created teacher request!");
            }
        } catch (error: any) {
            dispatch(setErrorMsg(error.message));
        }
    }

    useEffect(() => {
        if (expired) { return; }
        setExpiryDate(getRequiredDate(document, details.required_datefield));
    }, [document, details.required_datefield]);

    useEffect(() => {
        if (notIncompleteExpired) { return; }
        document && document?.reference ? reference.setValue(document.reference || '') : reference.reset();
    }, [document?.reference])

    useEffect(() => {
        if (notIncompleteExpired) { return; }
        setFile(getImageUrl(document, notIncompleteExpired))
    }, [document, notIncompleteExpired]);
    
    useEffect(() => {
        if (notIncompleteExpired) { return; }
        setQualification(document?.qualification || null)
    }, [document?.qualification, notIncompleteExpired]);



    // useEffect(() => {
    //     let skipped = document?.userSkipped;

    //     if (skipped !== undefined) {
    //         setSkip(skipped);
    //     }
    // }, [document?.userSkipped]);

    return (
        <>
            <RegModalWrapper title={title} handleUpdate={handleUpdate}
                state={document && getIncompleteState({
                    accreditation: { [`${employmentSetting}`]: { [`${reg.setLocation?.homeState}`]: { [`${details.type}`]: document } } }
                })} isAllComplete={isAllComplete()}
                expired={expired} optional={details.optional}
                handleUpdateRequest={handleUpdateRequest}
            >
                <Stack gap={pxToRem(24)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >{details.short_title}</h5>
                        {
                            !document?.state || document?.state === FieldState.INCOMPLETE ?
                                <Stack className={styles.infoBox}>
                                    <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8" /> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white" /> </svg>
                                    <small>{details.details_description}</small>
                                </Stack>
                                :
                                <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                                    <LightTooltip placement="bottom-start" title={<p className={styles.p}>{details.details_description}</p>}>
                                        <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8" /> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white" /> </svg>
                                    </LightTooltip>
                                    <p className={styles.p}>Why we need this information</p>
                                </Stack>
                        }
                    </Stack>
                    <Stack gap={pxToRem(24)}>
                        <Stack>
                            {
                                details.type === 'earlyChildcareQualification' ?
                                    <TeacherRegFormControl variant="standard" >
                                        <InputLabel>Qualification</InputLabel>
                                        <Select value={qualification} onChange={(e) => setQualification(e.target.value)}>
                                            {
                                                ECCQualificationLevels.sort((a,b) => a.localeCompare(b)).map((el, i) => (
                                                    <MenuItem key={i} value={el}>{ECCQualToTitle(el)}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </TeacherRegFormControl>
                                    :
                                    <UserRegTextField variant='standard' value={details.short_title} label={'Type'} focused={false} onChange={() => { }} />
                            }
                            <a href={details.website_link} target="_blank" style={{ textDecoration: 'none', width: 'fit-content' }}><small style={{ color: styles.primaryBlue500 }}>View Website</small></a>
                        </Stack>
                        {
                            details.required_datefield === null ?
                                null
                                :
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <CustomizedDatePicker value={expiryDate} onChange={(e) => setExpiryDate(e)} minDate={getMinDate(details.required_datefield)} maxDate={getMaxDate(details.required_datefield)} label={getDateLabel(details.required_datefield)} format="DD/MM/YYYY"
                                        onError={(newError) => setDateError(newError)}
                                        slotProps={{
                                            textField: {
                                                helperText: errorMessage
                                            }
                                        }}
                                        sx={{ flexGrow: 1, }}
                                    // disabled={details.optional && skip}
                                    />
                                </LocalizationProvider>
                        }
                        <UserRegTextField
                            value={reference.value}
                            onChange={(e) => reference.setValue(e.target.value)}
                            error={reference.hasTouched && reference.hasError} helperText={reference.getErrorMessage()}
                            label="Reference"
                        // disabled={details.optional && skip}
                        />
                        <RegFileUploadLabel file={file} reset={() => setFile(undefined)} />
                        {!file && details.upload_instructions ?
                            <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                                <LightTooltip placement="bottom-start" title={<p className={styles.p}>{details.upload_instructions}</p>}>
                                    <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8" /> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white" /> </svg>
                                </LightTooltip>
                                <p className={styles.p} style={{ fontSize: pxToRem(14) }}>What do I upload?</p>
                            </Stack>
                            : null}
                        <FileUpload file={file} onChangeCallback={onFileChange} extensions={'document'} iconColor={styles.primaryYellow500} />
                    </Stack>
                    {/* {
                        details.optional ?
                            <>
                                <Divider>Or</Divider>
                                <Stack direction='row' alignItems={'center'} justifyContent={'center'}>
                                    <Checkbox checked={skip} onChange={() => setSkip(prev => !prev)} />
                                    <small>I don't have this document</small>
                                </Stack>
                            </>
                            : null
                    } */}
                </Stack>
            </RegModalWrapper >
        </>
    );
};

const getRequiredDate = (accred?: AccreditationDocument, variant?: AccreditationRequiredField) => {
    if (!accred) { return null }
    switch (variant) {
        case null: return null;
        case 'registrationDate': return accred.registrationDate ? dayjs(accred.registrationDate, "DD-MM-YYYY") : null;
        default: return accred.expiryDate ? dayjs(accred.expiryDate, "DD-MM-YYYY") : null;
    }
}

const getDateLabel = (variant?: AccreditationRequiredField) => {
    switch (variant) {
        case 'registrationDate': return "Date Registered";
        // case 'expiryDate': case undefined: return 'Expiry Date';
        default: return 'Expiry Date';
    }
}

const getMinDate = (variant?: AccreditationRequiredField) => {
    switch (variant) {
        case 'registrationDate': return undefined;
        case 'expiryDate': return dayjs();
        default: return dayjs();
    }
}

const getMaxDate = (variant?: AccreditationRequiredField) => {
    switch (variant) {
        case 'registrationDate': return dayjs();
        case 'expiryDate': return undefined;
        default: return undefined;
    }
}


export default TeachingDocumentation;