import { HowDidYouHearType, StockRules } from "../firebase/types";
import { NSWAccreditationLevel } from "../firebase/types-teacher";

export type AccountType = 'teacher' | 'school' | 'childcareCentre';
export type TeacherEmploymentSetting = 'school' | 'earlyChildcare';
export type LocalTeacherEmploymentSetting = 'school' | 'earlyChildcare' | 'both';
export type UserType = {
  firstName?: string;
  preferredName?: string;
  lastName?: string;
  email?: string;
  accountType?: AccountType;
  dateRegistered?: string;
  contact?: string;
  homeCountry?: string;
  homeState?: string;
  lastVerified?: number;
  profilePictureURL?: string;
  registrationDevice?: string;

  schoolName?: string;
  schoolUid?: string;
  schools?: {
    [schoolId: string]: {
      name: string;
      suburb: string;
    }
  };
}
export const toUserType = (data: any): UserType => {
  return {
    firstName: data.firstName,
    preferredName: data.firstNameActual,
    lastName: data.lastName,
    email: data.email,
    accountType: data.accountType || null,
    dateRegistered: data.dateRegistered,
    contact: data.contact,
    homeCountry: data.homeCountry,
    homeState: data.homeState,
    lastVerified: data.lastVerified,
    profilePictureURL: data.profilePictureURL,
    registrationDevice: data.registrationDevice,


    schoolName: data.schoolName,
    schoolUid: data.schoolUid,
    schools: data.schools
  }
}

export type ShopCart = {
  fbId: string;
  stripeId: string;
  name: string;
  color: string;
  size: string;
  singleUnitPrice: number;
  previewImg: string;
  orderCount: number;
  date: number;
  stockRule: undefined | StockRules;
};

export type OtherDocument = {
  type?: string | null;
  submitted?: string | null;
  value?: string | null;
  state?: string | null;
  docTitle?: string | null;
}
export const toOtherDocument = (data: any): OtherDocument => {
  return {
    type: data.type,
    submitted: data.submitted,
    value: data.value,
    docTitle: data.docTitle
  }
}

export interface BankDetails {
  id?: string;
  accountName?: string;
  accountNumber?: string;
  bsb?: string;
  timeSubmitted?: number;
}

export type IdentificationDocument = {
  id?: string;
  type?: string;
  documentType?: string;
  country?: string;
  name?: string;
  birthDate?: string;
  reference?: string;
  value?: string;
  state?: string;
  docTitle?: string;
}
export const toIdentificationDocument = (data: any, id: string): IdentificationDocument => {
  return {
    id: id,
    type: data.type,
    documentType: data.documentType,
    country: data.country,
    name: data.name,
    birthDate: data.birthDate,
    reference: data.reference,
    value: data.value,
    docTitle: data.docTitle
  }
}


export type AccreditationRequiredField = 'expiryDate' | 'registrationDate' | null;
export type AccreditationDocumentDetails = {
  id?: string;
  type?: string;
  short_title?: string;
  long_title?: string;
  frequency?: string;
  requirement_description?: string;
  website_link?: string;
  details_description?: string;

  upload_instructions?: string;
  required_datefield?: AccreditationRequiredField;
  optional?: boolean;
  renewal_frequency_years?: number;
}

export type AccreditationDocument = {
  id?: string;
  accreditationType?: string;
  expiryDate?: string;
  dateSubmitted?: string;
  imageURL?: string | null;
  reference?: string;
  type?: string | null;
  state?: string;

  docTitle?: string | null;
  qualification?: string|null;
  registrationDate?: string;
}
export const toAccreditationDocument = (data: any, id: string): AccreditationDocument => {
  return {
    id: id,
    accreditationType: data.accreditationType,
    expiryDate: data.expiryDate,
    dateSubmitted: data.dateSubmitted,
    imageURL: data.imageURL,
    reference: data.reference,
    type: data.type,
    state: data.state,
    docTitle: data.docTitle,
    qualification: data.qualification,
    registrationDate: data.registrationDate,
    // userSkipped: data.userSkipped
  }
}
export type RegAccredDocs = { [name: string]: AccreditationDocument; } & { state?: string; }
export type TeacherFields = {
  accountState?: { dateApproved?: string; lastUpdated?: string; value?: string; firstTime?: boolean }
  firstName?: { value?: string; state?: string; }
  lastName?: { value?: string; state?: string; }
  preferredName?: { value?: string; state?: string; }
  email?: { value?: string; state?: string; }
  contact?: { value?: string; state?: string; }
  homeAddress?: { address?: string; country?: string; homeState?: string; lat?: number; long?: number; postCode?: string; suburb?: string; state?: string; }
  profilePicture?: { value?: string; quality?: string; type?: string; state?: string; }
  employmentSetting?: {
    [settingId: string]: {
      locationState: string;
      type: 'school' | 'earlyChildcare'
    }
  }

  // Account Registration Details
  workingRights?: { expiry?: string; reference?: string; value?: string; state?: string; url?: string; docTitle?: string, type?: string };
  primaryIdentification?: IdentificationDocument;
  secondaryIdentification?: IdentificationDocument;
  holdingIdPhotoURL?: IdentificationDocument;
  accreditation?: {
    // employmentType is of type TeacherEmploymentSetting ('school' | 'earlyChildcare')
    [employmentType: string]: { [locationState: string]: RegAccredDocs|null } | null
  }
  setLocation?: {
    country?: string; homeState?: string; lat?: number; long?: number;
  }
  firstEmergencyContact?: {
    contact?: string;
    name?: string;
    relationship?: string;
    state?: string;
  };
  secondEmergencyContact?: {
    contact?: string;
    name?: string;
    relationship?: string;
    state?: string;
  };
  reference?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    state?: string;
    requestedInterview?: boolean;
  };

  // Pre-employment Documents
  bank?: OtherDocument & BankDetails;
  super?: {
    state?: string;
    type?: string;
    submitted?: string;
    value?: string;

    docs?: { [docId: string]: OtherDocument; }
  };
  tax?: {
    state?: string;
    type?: string;
    submitted?: string;
    value?: string;

    docs?: { [docId: string]: OtherDocument; }
  };

  // Accreditation Level (Only for NSW School Teachers)
  service?: {
    serviceId?: string;
    timeUploaded?: number;
    uploadedBy?: string;
    urls?: {
      [docId: string]: {
        value: string,
        type?: string,
        docTitle?: string
      } | string;
    };
    userLevelNomination?: NSWAccreditationLevel;
    userLocation?: string;
  }

  // Additional account fields
  employmentDeclarations?: any;
  firstTime?: string;
  uiState?: { showProfileBanner?: boolean; }
  howDidYouHear?: HowDidYouHearType;
}

export const getTeacherFields = (data: any): TeacherFields => {
  return {
    ...(data.firstName ? { firstName: data.firstName } : undefined),
    ...(data.firstNameActual ? { preferredName: data.firstNameActual } : undefined),
    ...(data.lastName ? { lastName: data.lastName } : undefined),
    ...(data.email ? { email: data.email } : undefined),
    ...(data.contact ? { contact: data.contact } : undefined),
    ...(data.homeLocation ? { homeAddress: data.homeLocation } : undefined),
    ...(data.profilePicture ? { profilePicture: data.profilePicture } : undefined),
    ...(data.identity ? { workingRights: data.profilePicture } : undefined),
    ...(data.employmentDeclarations ? { employmentDeclarations: data.employmentDeclarations } : undefined),
  }
}

export type TeacherProfile = {
  profile?: {
    about?: string;
  };
  favouriteSubjects?: {
    [subject: string]: boolean;
  },
  years?: {
    max?: number;
    min?: number;
  },
  type?: {
    value?: string;
  }
}

export type TeacherNotifications = {
  bookingConfirmations?: boolean;
  cancellationNotifications?: boolean;
  completedBookings?: boolean;
  onboardingEmails?: boolean;
  sparkNews?: boolean;
  teacherUpdates?: boolean;
  accountReminderEmails?: boolean;
}

export enum REG_STEP {
  NAMES = 'names',
  CONTACT = 'contact',
  PROFILE_PHOTO = 'profilePhoto',
  WORKING_RIGHTS = 'workingRights',
  PRIMARY_DOC = 'primaryIdentity',
  SECONDARY_DOC = 'secondaryIdentity',
  HOLDING_ID = 'photoHoldingID',
  TEACHING_DOCS = 'teachingDocuments',
  REFERENCE = 'reference',
  EMERGENCY_CONTACTS = 'emergency',
  BANK = 'bank',
  SUPER = 'super',
  TAX = 'tax',
  REVIEW = 'review',

  ACCREDITATION_LEVEL = 'accreditationLevel'
}

export enum SCHOOL_REG_STEP {
  BASIC_DETAILS = 'basicDetails',
  SCHOOL_INFORMATION = 'schoolInformation',
  PRIMARY_BELLTIMES = 'primaryBellTimes',
  IS_PRIMARY_SECONDARY = 'isPrimarySecondary',
  SECONDARY_BELLTIMES = 'secondaryBellTimes',
  REVIEW_BELLTIMES = 'reviewBellTimes',
  BM_DETAILS = 'businessDetails',
  TERMS = 'terms',
  SUBMIT = 'submit'
}