import { useRef, useState } from "react";
import { IFileUpload } from ".";
import { getCroppedImg } from "../../helpers/canvasUtils";
import Cropper, { Area } from "react-easy-crop";
import { Box, IconButton, Modal, Slider, Stack, Tooltip } from "@mui/material";
import pxToRem from "../../helpers/pxToRem";
import { mapValue } from "../../helpers/utils";
import { modalStyle } from "../../pages/Dashboard/RegistrationModals";
import { PrimaryOutlinedButton, PrimaryButton } from "../Button";

import styles from './index.module.scss';
import UserAlert from "../AlertComponent/UserAlert";
import Compressor from "compressorjs";
import { getObjectURL } from "./helper";

interface IPhotoUpload extends IFileUpload {
    crop?: boolean;
}
const PhotoUpload = ({
    onChangeCallback,
    file,
    label,
    fileSizeLimitMb,
    disabled,
    compressionRatio,
    iconType, iconColor,
    multiple,
    crop
}: IPhotoUpload) => {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [fileSizeError, setFileSizeError] = useState<boolean>(false);
    const [fileTypeError, setFileTypeError] = useState<boolean>(false);

    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef<any>(null);

    const [imgSrc, setImgSrc] = useState<string | undefined>();
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [rotation, setRotation] = useState(0);
    const [cropArea, setCropArea] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1);

    const [cropLoading, setCropLoading] = useState(false);

    const handleFileUpload = (uploadFile: any) => {
        setFileSizeError(false);
        setFileTypeError(false);
        try {
            const fileSrc = uploadFile.files[0] as File;
            const fileName = fileSrc.name;
            const fileType = fileSrc.type;
            const fileSizeMb = fileSrc.size / 1024 / 1024;

            if (!fileName.includes('.')) { throw new Error("File needs a valid extension") };
            // const fileExt = fileName.split('.')[1]
            if (!fileType) { throw new Error("Undefined file type") };
            if (fileSizeMb > (fileSizeLimitMb || 1)) { setFileSizeError(true); return; }
            if (!fileType.includes("image/jpeg") && !fileType.includes("image/png")) { setFileTypeError(true); return; }

            if(crop) {
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    setImgSrc(reader.result?.toString() || undefined);
                    setIsFileSelected(true);
                });
                reader.readAsDataURL(fileSrc);
            } else {
                const fileUrl = getObjectURL(fileSrc);
                if (!fileUrl) { throw new Error("Could not process the uploaded asset.") };
                new Compressor(fileSrc, {
                    quality: compressionRatio && compressionRatio <= 1 && compressionRatio > 0 ? compressionRatio : 0.6,
                    success: (result) => { onChangeCallback(result as File); }
                })
            }

        } catch (error: any) { setErrorMsg(error.message); }
    }

    const handleDrag = (e: any) => {
        e.preventDefault(); e.stopPropagation();
        switch (e.type) {
            case 'dragenter': case 'dragover': setDragActive(true); break;
            case 'dragleave': setDragActive(false);
        }
    }
    const handleDrop = (e: any) => {
        e.preventDefault(); e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileUpload(e.dataTransfer);
        }
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFileUpload(e.target);
        }
    };

    const onCropComplete = (_croppedArea: Area, croppedAreaPixels: Area) => { setCroppedAreaPixels(croppedAreaPixels) }
    const rotateRight = () => { setRotation(rotation + 90); }
    const rotateLeft = () => { setRotation(rotation - 90); }

    const handleImageCrop = async () => {
        try {
            setCropLoading(true);
            console.log('cropping image');
            const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels, rotation);
            if (!croppedImage) { throw new Error(`An error occurred whilst cropping image. Please try again.`) }
            new Compressor(croppedImage,
                {
                    quality: 0.6,
                    error: (error) => { throw new Error(error.message) },
                    success: (compressedResult) => { onChangeCallback(compressedResult as File); }
                }
            );
            reset();
        } catch (error: any) {
            setErrorMsg(error.message);
        } finally {
            setCropLoading(false);
        }
    }

    const reset = () => {
        setDragActive(false);
        // setErrorStr(undefined);
        // setFileName(undefined);
        // setIsUploadBtnDisabled(true);
        setZoom(1);
        setImgSrc(undefined);
        setIsFileSelected(false);
        // setErrorDialog(false);
        setRotation(0);
        // setUploading(false);
    }


    return (
        <>
            <UserAlert open={errorMsg !== undefined} handleClose={() => setErrorMsg(undefined)} content={errorMsg}/>
            {
                file ?
                    <Stack className={styles.uploadedImage}>
                        <Box className={styles.imgContainer}>
                            <img src={typeof file.file === 'string' ? file.file : URL.createObjectURL(file.file as any)} alt={"Uploaded user profile"} />
                        </Box>
                    </Stack>
                    :
                    <>
                        <Stack spacing={1} className={`${styles.uploadPictureBox} ${dragActive ? styles.uploadPictureBoxActive : ''}`} onClick={() => { inputRef.current?.click() }} onDragLeave={handleDrag} onDragEnter={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                            <input ref={inputRef} type='file' multiple={false} accept='image/png, image/jpeg' onChange={handleChange} style={{ display: "none" }} />
                            <svg width="118" height="88" viewBox="0 0 118 88" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M81.7328 29.6537C94.1139 30.8169 105.136 33.3837 114.333 38.5939C126.422 45.4428 96.9756 48.0521 95.361 58.0917C93.6099 68.9797 123.492 82.5794 106.669 86.2481C91.476 89.5612 77.1342 83.0174 61.9441 79.6993C42.5102 75.454 8.89388 75.9184 1.96493 64.0114C-5.17383 51.7438 21.1084 44.5334 37.8561 36.5126C51.1665 30.1381 65.0339 28.0847 81.7328 29.6537Z" fill="#F5F5F5" /> <path d="M86.9277 14.5398V56.1398C86.9277 57.2169 86.0547 58.0898 84.9777 58.0898H43.3777C42.3008 58.0898 41.4277 57.2169 41.4277 56.1398V14.5398C41.4277 13.4629 42.3008 12.5898 43.3777 12.5898H84.9777C86.0547 12.5898 86.9277 13.4629 86.9277 14.5398Z" fill="white" stroke="#FCB218" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" /> <path d="M77.1767 2.84082H33.6268C32.5498 2.84082 31.6768 3.71387 31.6768 4.79082V48.3408" stroke="#FCB218" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" /> <path d="M41.4277 44.4403L59.122 38.5903L86.9277 48.3403" stroke="#FCB218" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" /> <path d="M72.3018 32.0903C69.6095 32.0903 67.4268 29.9076 67.4268 27.2153C67.4268 24.523 69.6095 22.3403 72.3018 22.3403C74.9941 22.3403 77.1768 24.523 77.1768 27.2153C77.1768 29.9076 74.9941 32.0903 72.3018 32.0903Z" stroke="#FCB218" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                            {/* { ? <label className={styles.uploadMaxsizeTitle}>{fileName}</label> : null} */}
                            <Stack direction={"row"} spacing={0.5}>
                                <label className={styles.uploadTitleBig}>Choose file</label>
                                <label className={styles.uploadTitleSmall}>or drag and drop</label>
                            </Stack>
                            <small>JPG or PNG only</small>
                            {fileSizeError ? <small style={{ color: styles.destructiveRed500 }}>Size limit Exceeded</small> : null}
                            {fileTypeError ? <small style={{ color: styles.destructiveRed500 }}>File type not supported</small> : null}
                        </Stack>
                    </>
            }
            <Modal open={isFileSelected}>
                <Stack sx={{ ...modalStyle, height: 'fit-content', maxHeight: '90%' }} className={styles.editModal}>
                    <IconButton onClick={reset}>
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="32" cy="32" r="32" fill="#E3E3E3" /> <path d="M44 20L20 44M20 20L44 44" stroke="#555555" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                    </IconButton>
                    <Stack sx={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                        <Stack gap={pxToRem(12)} alignItems={'flex-start'}>
                            <h4 className={styles.h4} style={{ color: styles.shades100, margin: 0 }}>Edit your photo</h4>
                            <p className={styles.p}>This image will be printed onto your Spark ID once your account is approved</p>
                        </Stack>
                        <Stack spacing={1} className={`${styles.pictureCropBox}`}>
                            {/* <Backdrop open={uploading} sx={{ position: 'absolute', height: '100%', width: '100%', zIndex: 10, borderRadius: '8px' }} /> */}
                            <Stack className={styles.photoEdit}>
                                <Tooltip title="Rotate Left" placement="right">
                                    <IconButton onClick={rotateLeft} size="small">
                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="26" cy="26" r="26" transform="matrix(-1 0 0 1 52 0)" fill="#C465A8" /> <path d="M37.5557 37.5554V35.5332C37.5557 30.6794 37.5557 28.2525 36.6111 26.3986C35.7802 24.7679 34.4543 23.4421 32.8236 22.6112C30.9697 21.6666 28.5428 21.6666 23.689 21.6666H14.4446M14.4446 21.6666L21.6668 28.8888M14.4446 21.6666L21.6668 14.4443" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                                    </IconButton>
                                </Tooltip>
                                <Stack className={styles.pictureCropBoxContainer}>
                                    <Cropper onCropComplete={onCropComplete} classes={{ containerClassName: styles.cropContainer }} image={imgSrc} cropShape="round" rotation={rotation} crop={cropArea} zoom={zoom} aspect={1} onCropChange={setCropArea} onZoomChange={setZoom} objectFit="cover" />
                                </Stack>
                                <Tooltip title="Rotate Right" placement="right">
                                    <IconButton onClick={rotateRight} size="small">
                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="26" cy="26" r="26" transform="matrix(-1 0 0 1 52 0)" fill="#C465A8" /> <path d="M14.4443 37.5554V35.5332C14.4443 30.6794 14.4443 28.2525 15.3889 26.3986C16.2198 24.7679 17.5457 23.4421 19.1764 22.6112C21.0303 21.6666 23.4572 21.6666 28.311 21.6666H37.5554M37.5554 21.6666L30.3332 28.8888M37.5554 21.6666L30.3332 14.4443" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <Stack>
                                <Stack style={{ justifyContent: 'center', alignItems: 'center', marginTop: pxToRem(8), position: 'relative' }}>
                                    {/* <Stack className={styles.button} direction={"row"} spacing={1} style={{ right:pxToRem(-50), position:'absolute', top:0 }}>
                                        { iUploadModal.fileUrl?<IconButton onClick={iUploadModal.fileDelete} size="small"><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none"> <path d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/> </svg></IconButton> : null }
                                    </Stack> */}
                                    <Stack direction={"row"} spacing={2} alignItems={'center'}>
                                        <IconButton disabled={zoom <= 1} onClick={() => setZoom(zoom - 0.1)} size="small"> <svg width="26" height="2" viewBox="0 0 26 2" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 1H25" stroke="#555555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg> </IconButton>
                                        <Slider className={styles.zoomSlider} size="small" value={zoom} step={0.1} min={1} max={3} valueLabelDisplay="auto" valueLabelFormat={(v) => `${mapValue(v, 1, 3, 0, 100)}`} onChange={(e, v) => { if (typeof (v) === "number") setZoom(v) }} />
                                        <IconButton disabled={zoom >= 3} onClick={() => setZoom(zoom + 0.1)} size="small"> <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13.5 1V25M1.5 13H25.5" stroke="#555555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg> </IconButton>
                                    </Stack>
                                    <label className={styles.ZoomLabel}>Zoom</label>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack className={styles.editModalBtns}>
                            <PrimaryOutlinedButton onClick={reset} disabled={cropLoading} sx={{ maxHeight: '36px' }}>Replace Photo</PrimaryOutlinedButton>
                            <PrimaryButton onClick={handleImageCrop} loading={cropLoading} sx={{ maxHeight: '36px' }}>Save Photo</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Modal>
        </>
    )
}

export default PhotoUpload;