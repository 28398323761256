export const getErrorMsgForTextInput = (
  text: string,
  inputName: string,
  minLength?: number | null,
  maxLength?: number | null,
  canBeEmpty?: boolean,
  cannotBeEmpty?: boolean,
) => {
  if(canBeEmpty && text.trim().length === 0) {
    return;
  }
  if(cannotBeEmpty && text.trim().length === 0) {
    return `${inputName} cannot be empty`;
  }
  if (text.trim().length === 0) {
    return `Please enter ${inputName}`;
  } else if (minLength && text.trim().length < (minLength ?? 0)) {
    return `${inputName} minimum length is ${minLength} characters`;
  } else if (maxLength && text.trim().length > (maxLength ?? 0)) {
    return `${inputName} maximum length is ${maxLength} characters`;
  }
};
export const getErrorMsgForEmailInput = (email: string) => {
  if (email.trim().length === 0) {
    return "Please enter your email";
  } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return "Please enter a valid email address";
  }
};

export const getErrorMsgForPhoneNumberInput = (phoneNumber: string) => {
  if (phoneNumber.trim().length === 0) {
    return "Please enter your phone number";
  } else if (
    !/^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/.test(
      phoneNumber
    ) ||
    phoneNumber.length < 8
  ) {
    return "Phone number entered appears incorrect";
  }
};

export const getErrorMsgForPhoneNumberInputMobile = (phoneNumber: string) => {
  if (phoneNumber.trim().length === 0) {
    return "Please enter your phone number";
  } else if (
    !/^0[0-9]{3}[ ]{0,1}[0-9]{3}[ ]{0,1}[0-9]{3}$/.test(
      phoneNumber
    )
  ) {
    return "Phone number entered appears incorrect";
  }
};

export const getErrorMsgForLandline = (phoneNumber: string) => {
  if (phoneNumber.trim().length === 0) {
    return "Please enter your phone number";
  } else if (
    !/^[()]{1}[0-9]{2}[)]{1}[ ]{0,1}[0-9]{4}[ ]{0,1}[0-9]{4}$/.test(
      phoneNumber
    )
  ) {
    return "Phone number entered appears incorrect";
  }
};

export const getErrorMsgForPasswordInput = (
  password: string,
  forLogin?: boolean,
  confirmPassword?: string,
  differentTo?: string
) => {
  if (forLogin) {
    if (password.trim().length < 5) {
      return "Enter password";
    }
    return;
  }
  if(differentTo && password === differentTo) {
    return "Enter a different password"
  }
  if(confirmPassword && password !== confirmPassword) {
    return "Passwords do not match"
  }
  if (
    password.length < 8 ||
    !/\d/.test(password) ||
    !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)
  ) {
    return "Password must be at least 8 characters long, include at least one digit, and contain at least one special character";
  }
};


export const getErrorMsgForVITNo = (vit: string) => {
  if (vit.trim().length === 0) {
    return "Please enter your VIT number";
  } else if (!/^[0-9]{9}$/.test(vit)) {
    return "Please enter a valid VIT Number";
  }
};

export const getAccountNumberError = (num: string) => {
  let withoutDash = num.trim().replaceAll(' ', '');
  if (withoutDash.length === 0) {
    return "Please enter your account number";
  } else if (withoutDash.length < 5 || withoutDash.length > 10) {
    return "Account number should be 5 to 10 digits"
  }
}