import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UserRegTextField } from "../../../../components/TextField";
import { auth } from "../../../../firebase/configs";
import pxToRem from "../../../../helpers/pxToRem";
import useTextInput from "../../../../hooks/useTextInput";
import { RootState } from "../../../../redux/store";
import styles from "../index.module.scss";
import { RegModalWrapper } from "../ModalContent";
import { setStep, updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { GoogleMapsTextField, PlaceType } from "../../../../components/AutoComplete";
import { useEffect, useState } from "react";
import { getIncompleteState } from "../../ReviewPage/helper";
import { getErrorMsgForPhoneNumberInputMobile, getErrorMsgForTextInput } from "../../../../helpers/inputValidators";
import { isObjectEmpty } from "../../../../firebase/general-apis";
import { formatContactNumber } from "./helper";
import { isValidPlaceType } from "../../components/helper";
import InputMask from "react-input-mask";
import { empSettingLocaltoFb } from "../../../../firebase/teacherApi";
import { FieldState } from "../../../../firebase/types-teacher";


const Contact = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.user);
    const reg = useSelector((state: RootState) => state.userReg.fields);
    const step = useSelector((state: RootState) => state.userReg.step);

    const email = useTextInput({ inputValidator: (input: string) => { return undefined }, defaultValue: reg.email?.value || auth.currentUser?.email || user?.email });
    const contact = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForPhoneNumberInputMobile(input) }, defaultValue: reg.contact?.value || user?.contact });
    const homeAddress = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "home address") }, defaultValue: reg.homeAddress?.address });
    const [homeLocation, setHomeLocation] = useState<PlaceType | null>(null);
    const employmentSetting = useSelector((state: RootState) => state.userReg.employmentSetting);

    const handleHomeLocationChange = (place: PlaceType | null) => {
        setHomeLocation(place);
    }

    const getLocation = (location: PlaceType | null) => {
        if (!location) return {};
        let address = location.address_components;
        let geometry = location.geometry;
        if (!address || !geometry) return {};

        let countryObj = address.filter(el => el.types.includes('country'))[0];
        if (!countryObj) return {};
        let country = countryObj.long_name;

        let stateObj = address.filter(el => el.types.includes('administrative_area_level_1'))[0];
        if (!stateObj) return {};
        let state = stateObj.short_name;

        let suburbObj = address.filter(el => el.types.includes('locality'))[0];
        if (!suburbObj) return {}
        let suburb = suburbObj.long_name;

        let postal_codeObj = address.filter(el => el.types.includes('postal_code'))[0];
        if (!postal_codeObj) return {};
        let postal_code = postal_codeObj.long_name;

        return {
            ...(country ? { country: country } : null),
            ...(state ? { state: state } : null),
            ...(suburb ? { suburb: suburb } : null),
            ...(postal_code ? { postal_code: postal_code } : null),
            ...(geometry ? { lat: geometry.location.lat(), lng: geometry.location.lng() } : null)
        }
    }

    const isAllComplete = () => {
        return !email.hasError && !contact.hasError && (homeAddress.value === reg.homeAddress?.address || (homeLocation !== null && isValidPlaceType(homeLocation)))
    }

    const handleUpdate = () => {
        email.setHasTouched(true);
        contact.setHasTouched(true);
        homeAddress.setHasTouched(true);
        const location = getLocation(homeLocation);
        let updateData: UserUpdate = {}
        if (!user || !reg) { return; }

        let formattedContact = formatContactNumber(contact.value);

        updateData = {
            user: {
                path: '/',
                data: {
                    ...(formattedContact !== user.contact ? { contact: !contact.hasError ? formattedContact : null } : null),
                    ...(homeAddress.value !== reg.homeAddress?.address && location.country !== user.homeCountry ? { homeCountry: location.country ? location.country : null } : null),
                    ...(homeAddress.value !== reg.homeAddress?.address && location.state !== user.homeState ? { homeState: location.state ? location.state : null } : null),
                }
            },
            teacher: {
                path: '/',
                data: {
                    ...(formattedContact !== reg.contact?.value ? contact.hasTouched && !contact.hasError ? { contact: { value: formattedContact, state: 'submitted' } } : { contact: { value: null, state: null } } : null),
                    ...(!reg.email?.value && !email.hasError ? {email: {value: email.value, state: FieldState.SUBMITTED}} : null),
                    ...(homeAddress.value !== reg.homeAddress?.address ? isObjectEmpty(location) ? { homeLocation: { state: 'incomplete' } } :
                        {
                            homeLocation: {
                                address: homeAddress.value !== '' ? homeAddress.value : null,
                                country: location.country ? location.country : null,
                                homeState: location.state ? location.state : null,
                                suburb: location.suburb ? location.suburb : null,
                                postCode: location.postal_code ? location.postal_code : null,
                                lat: location.lat ? location.lat : null,
                                long: location.lng ? location.lng : null,
                                state: homeAddress.value !== '' ? 'submitted' : 'incomplete',
                            },
                            setLocation: {
                                country: location.country ? location.country : null,
                                homeState: location.state ? location.state : null,
                                lat: location.lat ? location.lat : null,
                                long: location.lng ? location.lng : null,
                            },

                        }
                        : null),
                    ...(location.state && location.state !== user.homeState ? {
                        employmentSetting: empSettingLocaltoFb(location.state, employmentSetting)
                    } : null)
                }
            }
        }

        dispatch(updateFields(updateData));
    };

    useEffect(() => {
        reg.contact?.value ? contact.setValue(reg.contact?.value) : contact.reset();
        reg.homeAddress?.address ? homeAddress.setValue(reg.homeAddress.address) : homeAddress.reset();
    }, [reg.contact, reg.homeAddress])

    const handleNext = () => {
        handleUpdate();
        dispatch(setStep(step + 1));
    }

    const handleBack = () => {
        handleUpdate();
        dispatch(setStep(step - 1));
    }

    return (
        <RegModalWrapper title={"Basic Details"} handleUpdate={handleUpdate}
            state={getIncompleteState({
                email: reg.email,
                contact: reg.contact,
                homeAddress: reg.homeAddress
            })} isAllComplete={isAllComplete()}
        >
            <Stack gap={pxToRem(24)} className={styles.modalContainer}>
                <Stack className={styles.titleContainer}>
                    <h5 className={styles.h5} >Contact Information</h5>
                    <p className={styles.p}>Your contact number is provided to schools on the day you are scheduled to work at their school.</p>
                </Stack>
                <UserRegTextField
                    value={email.value}
                    onChange={(e) => email.setValue(e.target.value)}
                    error={email.hasTouched && email.hasError} helperText={email.getErrorMessage()}
                    label="Email" disabled
                />
                <InputMask
                    mask="9999 999 999"
                    maskPlaceholder={''}
                    value={contact.value}
                    onChange={(e) => contact.setValue(e.target.value)}
                    disabled={false}
                >
                    <UserRegTextField
                        error={contact.hasTouched && contact.hasError} helperText={contact.getErrorMessage()}
                        label="Contact Number"
                    />
                </InputMask>
                <Stack className={styles.titleContainer}>
                    <h5 className={styles.h5} >Location</h5>
                    <p className={styles.p}>Your home address is an employee record; it is not provided to other users.</p>
                </Stack>
                <GoogleMapsTextField
                    value={homeLocation}
                    setValue={handleHomeLocationChange}
                    inputValue={homeAddress.value}
                    setInputValue={homeAddress.setValue}
                    error={homeAddress.hasTouched && (homeAddress.hasError || (homeLocation !== null && !isValidPlaceType(homeLocation)))} helperText={homeAddress.getErrorMessage() ? homeAddress.getErrorMessage() : ((homeLocation !== null && !isValidPlaceType(homeLocation)) ? 'Address requies state, postcode and geocode' : undefined)}
                    label="Home Address"
                />
            </Stack>
        </RegModalWrapper>
    )
};

export default Contact;