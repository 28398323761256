import { useDispatch, useSelector } from "react-redux";
import { RegModalWrapper } from "../ModalContent";
import pxToRem from "../../../../helpers/pxToRem";
import styles from "../index.module.scss";
import { Stack } from "@mui/material";
import { UserRegTextField } from "../../../../components/TextField";
import { getErrorMsgForPhoneNumberInputMobile, getErrorMsgForTextInput } from "../../../../helpers/inputValidators";
import useTextInput from "../../../../hooks/useTextInput";
import { RootState } from "../../../../redux/store";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { getIncompleteState } from "../../ReviewPage/helper";
import { useEffect } from "react";
import { formatContactNumber } from "./helper";
import InputMask from "react-input-mask";
import { FieldState } from "../../../../firebase/types-teacher";


const EmergencyContact = () => {
    const dispatch = useDispatch();
    const reg = useSelector((state: RootState) => state.userReg.fields);

    const pName = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Name") }, defaultValue: reg.firstEmergencyContact?.name || "" });
    const pRelationship = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Relationship") }, defaultValue: reg.firstEmergencyContact?.relationship || "" });
    const pContact = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForPhoneNumberInputMobile(input) }, defaultValue: reg.firstEmergencyContact?.contact });

    const sName = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Name") }, defaultValue: reg.secondEmergencyContact?.name || "" });
    const sRelationship = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Relationship") }, defaultValue: reg.secondEmergencyContact?.relationship || "" });
    const sContact = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForPhoneNumberInputMobile(input) }, defaultValue: reg.secondEmergencyContact?.contact });

    const isAllComplete = (first?: boolean, second?: boolean) => {
        if (first) {
            return !pName.hasError && pName.value !== '' &&
                !pRelationship.hasError && pRelationship.value !== '' &&
                !pContact.hasError && pContact.value !== '';
        }
        if (second) {
            return !sName.hasError && sName.value !== '' &&
                !sRelationship.hasError && sRelationship.value !== '' &&
                !sContact.hasError && sContact.value !== ''
        }

        return !pName.hasError && pName.value !== '' &&
            !pRelationship.hasError && pRelationship.value !== '' &&
            !pContact.hasError && pContact.value !== '' &&
            !sName.hasError && sName.value !== '' &&
            !sRelationship.hasError && sRelationship.value !== '' &&
            !sContact.hasError && sContact.value !== ''
    }

    const isSomeComplete = (first?: boolean, second?: boolean) => {
        if (first) {
            return !pName.hasError && pName.value !== '' ||
                !pRelationship.hasError && pRelationship.value !== '' ||
                !pContact.hasError && pContact.value !== '';
        }
        if (second) {
            return !sName.hasError && sName.value !== '' ||
                !sRelationship.hasError && sRelationship.value !== '' ||
                !sContact.hasError && sContact.value !== ''
        }

        return !pName.hasError && pName.value !== '' ||
            !pRelationship.hasError && pRelationship.value !== '' ||
            !pContact.hasError && pContact.value !== '' ||
            !sName.hasError && sName.value !== '' ||
            !sRelationship.hasError && sRelationship.value !== '' ||
            !sContact.hasError && sContact.value !== ''
    }

    const handleUpdate = () => {

        let formattedPrimaryContact = pContact.hasError ? undefined : formatContactNumber(pContact.value);
        let formattedSecondaryContact = sContact.hasError ? undefined : formatContactNumber(sContact.value);

        let updateData: UserUpdate = {};

        updateData = {
            teacher: {
                path: '/',
                data: {
                    firstEmergencyContact: {
                        name: !pName.hasError ? pName.value !== '' ? pName.value : null : null,
                        contact: !pContact.hasError ? pContact.value !== '' ? formattedPrimaryContact : null : null,
                        relationship: !pRelationship.hasError ? pRelationship.value !== '' ? pRelationship.value : null : null,
                        state: isAllComplete(true) ? FieldState.SUBMITTED : isSomeComplete(true) ? FieldState.INCOMPLETE : null
                    },
                    secondEmergencyContact: {
                        name: !sName.hasError ? sName.value !== '' ? sName.value : null : null,
                        contact: !sContact.hasError ? sContact.value !== '' ? formattedSecondaryContact : null : null,
                        relationship: !sRelationship.hasError ? sRelationship.value !== '' ? sRelationship.value : null : null,
                        state: isAllComplete(undefined, true) ? FieldState.SUBMITTED : isSomeComplete(true) ? FieldState.INCOMPLETE : null
                    }
                }
            }
        }
        dispatch(updateFields(updateData));
    }

    useEffect(() => {
        reg.firstEmergencyContact?.name ? pName.setValue(reg.firstEmergencyContact.name) : pName.reset();
        reg.firstEmergencyContact?.relationship ? pRelationship.setValue(reg.firstEmergencyContact.relationship) : pRelationship.reset();
        reg.firstEmergencyContact?.contact ? pContact.setValue(reg.firstEmergencyContact.contact) : pContact.reset();
    }, [reg.firstEmergencyContact])

    useEffect(() => {
        reg.secondEmergencyContact?.name ? sName.setValue(reg.secondEmergencyContact.name) : sName.reset();
        reg.secondEmergencyContact?.relationship ? sRelationship.setValue(reg.secondEmergencyContact.relationship) : sRelationship.reset();
        reg.secondEmergencyContact?.contact ? sContact.setValue(reg.secondEmergencyContact.contact) : sContact.reset();
    }, [reg.secondEmergencyContact])

    return (
        <>
            <RegModalWrapper title="Emergency Contacts" handleUpdate={handleUpdate}
                state={getIncompleteState({
                    firstEmergencyContact: reg.firstEmergencyContact,
                    secondEmergencyContact: reg.secondEmergencyContact,
                })} isAllComplete={isAllComplete()}
            >
                <Stack gap={pxToRem(24)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >Emergency Contacts</h5>
                        <p className={styles.p}>Your emergency contacts will be accessible to schools that confirm you to work at their school in the event that an emergency occurs.</p>
                    </Stack>
                    <p className={styles.emergencySubtitle}>Primary Contact</p>
                    <Stack direction="row" gap={pxToRem(24)}>
                        <UserRegTextField
                            value={pName.value}
                            onChange={(e) => pName.setValue(e.target.value)}
                            error={pName.hasTouched && pName.hasError} helperText={pName.getErrorMessage()}
                            label="Name" sx={{ flexGrow: 1 }}
                        />
                        <UserRegTextField
                            value={pRelationship.value}
                            onChange={(e) => pRelationship.setValue(e.target.value)}
                            error={pRelationship.hasTouched && pRelationship.hasError} helperText={pRelationship.getErrorMessage()}
                            label="Relationship" sx={{ flexGrow: 1 }}
                        />
                    </Stack>
                    {/* <UserRegTextField
                        value={pContact.value}
                        onChange={(e)=>pContact.setValue(formatOnChange(e.target.value))}
                        error={pContact.hasTouched && pContact.hasError} helperText={pContact.getErrorMessage()}
                        label="Contact"
                    /> */}
                    <InputMask
                        mask="9999 999 999"
                        maskPlaceholder={''}
                        value={pContact.value}
                        onChange={(e) => pContact.setValue(e.target.value)}
                        disabled={false}
                    >
                        <UserRegTextField
                            error={pContact.hasTouched && pContact.hasError} helperText={pContact.getErrorMessage()}
                            label="Contact"
                        />
                    </InputMask>

                    <p className={styles.emergencySubtitle}>Secondary Contact</p>
                    <Stack direction="row" gap={pxToRem(24)}>
                        <UserRegTextField
                            value={sName.value}
                            onChange={(e) => sName.setValue(e.target.value)}
                            error={sName.hasTouched && sName.hasError} helperText={sName.getErrorMessage()}
                            label="Name" sx={{ flexGrow: 1 }}
                        />
                        <UserRegTextField
                            value={sRelationship.value}
                            onChange={(e) => sRelationship.setValue(e.target.value)}
                            error={sRelationship.hasTouched && sRelationship.hasError} helperText={sRelationship.getErrorMessage()}
                            label="Relationship" sx={{ flexGrow: 1 }}
                        />
                    </Stack>
                    {/* <UserRegTextField
                        value={sContact.value}
                        onChange={(e)=>sContact.setValue(formatOnChange(e.target.value))}
                        error={sContact.hasTouched && sContact.hasError} helperText={sContact.getErrorMessage()}
                        label="Contact"
                    /> */}

                    <InputMask
                        mask="9999 999 999"
                        maskPlaceholder={''}
                        value={sContact.value}
                        onChange={(e) => sContact.setValue(e.target.value)}
                        disabled={false}
                    >
                        <UserRegTextField
                            error={sContact.hasTouched && sContact.hasError} helperText={sContact.getErrorMessage()}
                            label="Contact"
                        />
                    </InputMask>

                </Stack>
            </RegModalWrapper>
        </>
    );
};

export default EmergencyContact;