import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import styles from "./index.module.scss";
import TableRowsIcon from "@mui/icons-material/TableRows";
import CancelIcon from "@mui/icons-material/Cancel";
import {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {FutureTerm, TermData} from "./types";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { AsyncStatus } from "../../firebase/types";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CircularProgress from "@mui/material/CircularProgress";

interface ITermsPreviewBox {
  termsConfig: TermData[];
  upcomingTermsConfig: { title: string; terms: FutureTerm[] }[];
}

const TermsPreviewBox = ({
  termsConfig,
  upcomingTermsConfig,
}: ITermsPreviewBox) => {
  const [selectedTerm, setSelectedTerm] = useState<TermData>(termsConfig[0]);
  const screenType = useSelector((state: RootState) => state.ui.screenType);
  const [termsSelectionPanelOpen, setTermsSelectionPanelOpen] = useState(true);
  const [isLoaded, setIsLoaded] = useState<{ [termUid: string]: AsyncStatus }>(
    {}
  );

  useEffect(() => {
    return () => {
      console.log(upcomingTermsConfig.length)
    };
  }, []);


  return (
    <Paper elevation={10}>
      <Stack direction={"row"} className={styles.termsContainer}>
        {selectedTerm && termsConfig.length > 0 ? (
          <>
            <Stack
              className={`${styles.termsSelectionPanel} ${
                termsSelectionPanelOpen
                  ? styles["termsSelectionPanel--opened"]
                  : styles["termsSelectionPanel--closed"]
              }`}
            >
              <Stack
                justifyContent={"center"}
                className={`${
                  termsSelectionPanelOpen
                    ? styles["buttonWrapper--opened"]
                    : styles["buttonWrapper--closed"]
                }`}
              >
                {termsSelectionPanelOpen ? (
                  <CancelIcon
                    className={styles.TableRowsIcon}
                    onClick={() => setTermsSelectionPanelOpen(false)}
                  />
                ) : (
                  <TableRowsIcon
                    className={styles.TableRowsIcon}
                    onClick={() => setTermsSelectionPanelOpen(true)}
                  />
                )}
              </Stack>
              <Stack className={styles.termsScrollBar}>
                <Box
                  className={styles.previewPanelDeivider}
                  sx={{
                    display:
                      screenType === "PC" || termsSelectionPanelOpen
                        ? "unset"
                        : "none",
                  }}
                >
                  {screenType === "PC"
                    ? "Documents"
                    : termsSelectionPanelOpen
                    ? "Documents"
                    : ""}
                </Box>
                {termsConfig.map((term) => (
                  <div
                    key={`${term.uid}`}
                    style={{
                      display:
                        screenType === "PC" || termsSelectionPanelOpen
                          ? "flex"
                          : "none",
                    }}
                    className={`${styles.termTitle} ${
                      selectedTerm.uid === term.uid
                        ? styles["termTitle--selected"]
                        : styles["termTitle--notSelected"]
                    }`}
                    onClick={() => {
                      setSelectedTerm(term);
                      if (screenType === "PC") return;
                      setTermsSelectionPanelOpen(false);
                    }}
                  >
                    <div style={{ display: "inline-block" }}>{term.title}</div>
                    {selectedTerm.uid === term.uid ? (
                      <OpenInNewIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => window.open(term.url)}
                      />
                    ) : null}
                  </div>
                ))}

                { upcomingTermsConfig[0]?.terms?.length !== 0 && upcomingTermsConfig.map((upcomingTerm) => (
                  <Stack
                    key={`upcomingTerm-${upcomingTerm.title}`}
                    className={styles.upcomingTermsGroup}
                    direction='column'
                  >
                    <Box
                      className={styles.previewPanelDeivider}
                      sx={{
                        display:
                          screenType === "PC" || termsSelectionPanelOpen
                            ? "unset"
                            : "none",
                      }}
                    >
                      {screenType === "PC"
                        ? upcomingTerm.title
                        : termsSelectionPanelOpen
                        ? upcomingTerm.title
                        : ""}
                    </Box>
                    {upcomingTerm.terms.map((term) => (
                      <div
                        key={`${term.uid}`}
                        className={`${styles.termTitle} ${
                          selectedTerm.uid === term.uid
                            ? styles["termTitle--selected"]
                            : styles["termTitle--notSelected"]
                        }`}
                        style={{
                          display:
                            screenType === "PC" || termsSelectionPanelOpen
                              ? "flex"
                              : "none",
                        }}
                        onClick={() => {
                          setSelectedTerm(term);
                          if (screenType === "PC") return;
                          setTermsSelectionPanelOpen(false);
                        }}
                      >
                        {term.title}
                        {selectedTerm.uid === term.uid ? (
                          <OpenInNewIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => window.open(term.url)}
                          />
                        ) : null}
                       <Box>
                          Effective On {term.effectiveDate}
                       </Box>
                      </div>
                    ))}
                  </Stack>
                ))}
              </Stack>
            </Stack>
            <div className={`${styles.previewPanel} ${styles.scrollable}`}>
              {[
                ...termsConfig,
                ...upcomingTermsConfig
                  .map((upcomingTerms) => upcomingTerms.terms)
                  .flat(),
              ].map((term) => (
                <>
                  <Stack
                    className={styles.iframeError}
                    sx={{
                      display:
                        selectedTerm.uid === term.uid
                          ? isLoaded[term.uid] === "failure"
                            ? "flex"
                            : "none"
                          : "none",
                    }}
                  >
                    <PictureAsPdfIcon className={styles.PictureAsPdfIcon} />
                    <p style={{ textAlign: "center" }} className='paragraph1'>
                      Failed to preview, <br /> please follow the below link{" "}
                      <a href={selectedTerm.url}>to the PDF!</a>
                    </p>
                  </Stack>
                  <Stack
                    className={styles.loadingSpinnerWrapper}
                    style={{
                      display:
                        selectedTerm.uid === term.uid
                          ? isLoaded[term.uid]
                            ? "none"
                            : "flex"
                          : "none",
                    }}
                  >
                    <CircularProgress
                      className={styles.CircularProgress}
                      size={85}
                      disableShrink
                      thickness={5.5}
                    />
                  </Stack>
                  <iframe
                    src={`https://docs.google.com/gview?url=${encodeURIComponent(
                      term.url
                    )}&embedded=true`}
                    onLoad={(e) => {
                      setIsLoaded((preState) => ({
                        ...preState,
                        [term.uid]: "success",
                      }));
                    }}
                    onError={(e) => {
                      setIsLoaded((preState) => ({
                        ...preState,
                        [term.uid]: "failure",
                      }));
                    }}
                    onAbort={(e) => {
                      setIsLoaded((preState) => ({
                        ...preState,
                        [term.uid]: "failure",
                      }));
                    }}
                    width='100%'
                    height='100%'
                    className={styles.scrollable}
                    allowFullScreen
                    style={{
                      display:
                        selectedTerm.uid === term.uid && isLoaded[term.uid]
                          ? "unset"
                          : "none",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      Failed to preview, please follow the below link{" "}
                      <a href={selectedTerm.url}>to the PDF!</a>
                    </p>
                  </iframe>
                </>
              ))}
            </div>
          </>
        ) : null}
      </Stack>
    </Paper>
  );
};

export default TermsPreviewBox;
