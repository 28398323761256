import { ReactNode, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import { getMimeTypes, getObjectURL } from './helper';
import UserAlert from '../AlertComponent/UserAlert';
import { Stack } from '@mui/material';
import Compressor from "compressorjs";

export interface FileType {file: File | string, fileName?: string};
export interface MultiFileType extends FileType {id: string};
export type PresetExtensions = 'document' | 'photo';
const getPresetExtensions = (e: PresetExtensions) => {
    switch(e) {
        case 'document': return ['pdf', 'png', 'jpg', 'jpeg'];
        case 'photo': return ['png', 'jpg', 'jpeg'];
        default: return undefined
    }
}

export type IconType = 'image' | 'document';
export interface IFileUpload {
    onChangeCallback: (file: File) => void;
    file?: FileType;
    // fileName?: string;
    label?: ReactNode;
    
    extensions?: string[] | PresetExtensions;
    fileSizeLimitMb?: number;
    compressionRatio?: number;

    iconType?: IconType;
    iconColor?: string;

    disabled?: boolean;
    multiple?: boolean;
}
const FileUpload = ({
    onChangeCallback,
    extensions,
    file,
    // fileName,
    label,
    fileSizeLimitMb,
    disabled,
    compressionRatio,
    iconType, iconColor,
    multiple
}: IFileUpload) => {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [fileSizeError, setFileSizeError] = useState<boolean>(false);
    const [fileTypeError, setFileTypeError] = useState<boolean>(false);

    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef<any>(null);
    const ext = useMemo(() => typeof extensions === 'string' ? getPresetExtensions(extensions) : extensions, [extensions])

    const handleFileUpload = (uploadFile: any) => {
        setFileSizeError(false);
        setFileTypeError(false);
        try {
            const fileSrc = uploadFile.files[0] as File;
            const fileName = fileSrc.name;
            const fileType = fileSrc.type;
            const fileSizeMb = fileSrc.size / 1024 / 1024;

            if (!fileName.includes('.')) { throw new Error("File needs a valid extension") };
            const fileExt = fileName.split('.')[fileName.split('.').length - 1]
            if (!fileType) { throw new Error("Undefined file type") };
            if (fileSizeMb > (fileSizeLimitMb || 1)) { setFileSizeError(true); return; }

            if (ext && !ext.includes(fileExt)) { setFileTypeError(true); return; }

            const fileUrl = getObjectURL(fileSrc);
            if (!fileUrl) { throw new Error("Could not process the uploaded asset.") };

            switch (fileExt) {
                case 'pdf': onChangeCallback(fileSrc); break;
                default: {
                    new Compressor(fileSrc, {
                        quality: compressionRatio && compressionRatio <= 1 && compressionRatio > 0 ? compressionRatio : 0.6,
                        success: (result) => { onChangeCallback(result as File); }
                    })
                }
            }
        } catch (error: any) { setErrorMsg(error.message); }
    }

    const handleDrag = (e: any) => {
        e.preventDefault(); e.stopPropagation();
        switch (e.type) {
            case 'dragenter': case 'dragover': setDragActive(true); break;
            case 'dragleave': setDragActive(false);
        }
    }
    const handleDrop = (e: any) => {
        e.preventDefault(); e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileUpload(e.dataTransfer);
        }
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFileUpload(e.target);
        }
    };

    return (
        <>
            <UserAlert open={errorMsg !== undefined} handleClose={() => setErrorMsg(undefined)} content={errorMsg}/>
            {label}
            <form
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
            >
                <input
                    ref={inputRef}
                    type='file'
                    id='input-file-upload'
                    multiple={true}
                    accept={getMimeTypes(ext).join(',')}
                    onChange={handleChange}
                    style={{display: 'none'}}
                    disabled={disabled || file !== undefined}
                />
                <label
                    htmlFor='input-file-upload'
                    className={`${styles.labelContainer} ${dragActive ? styles["drag-active"] : ""} ${disabled ? styles['labelContainer-disabled'] : ''} ${!multiple && file ? styles['labelContainer--withfile']: ''}`}
                >
                    <Stack>
                        {
                            !multiple && file ?
                                <Stack alignItems={'center'} sx={{cursor: 'pointer'}}
                                    onClick={() => {
                                        let newLink;
                                        if (typeof file.file === 'string') { newLink = file.file } else { newLink = URL.createObjectURL(file.file as any); }
                                        window && window.open(newLink, '_blank');
                                    }}>
                                    <svg className={styles['docPath--purple']} width="94" height="80" viewBox="0 0 94 80" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M65.3461 34.907C75.3381 35.8191 84.2334 37.8319 91.6556 41.9175C101.412 47.288 77.6476 49.3342 76.3445 57.2067C74.9313 65.7447 99.0473 76.4089 85.4704 79.2857C73.2091 81.8837 61.6347 76.7524 49.3758 74.1504C33.6918 70.8215 6.56218 71.1857 0.970253 61.8487C-4.79101 52.229 16.4197 46.5749 29.9358 40.2854C40.6778 35.2868 51.8693 33.6767 65.3461 34.907Z" fill="#F5F5F5" /> <path className={styles['docPath--purple']} d="M34.9539 59.6099H59.046C63.9051 59.6099 66.4137 57.0561 66.4137 52.1744V31.947H49.4634C46.6384 31.947 45.2823 30.591 45.2823 27.7659V10.6348H34.9539C30.1174 10.6348 27.5862 13.1886 27.5862 18.0929V52.1744C27.5862 57.0561 30.1174 59.6099 34.9539 59.6099ZM49.8928 28.6473H66.1877C66.0521 27.6077 65.3063 26.6359 64.1311 25.4381L51.7687 12.9174C50.6386 11.7648 49.6216 10.9964 48.582 10.8608V27.3365C48.6046 28.2179 49.034 28.6473 49.8928 28.6473ZM47 53.7564C45.9603 53.7564 45.1015 52.9654 45.1015 51.9484V44.4902L45.2823 41.1228L43.5647 42.8856L41.7115 44.7614C41.3725 45.1004 40.8978 45.3265 40.4458 45.3265C39.4514 45.3265 38.7056 44.6484 38.7056 43.6766C38.7056 43.1342 38.9316 42.7274 39.3158 42.3658L45.5535 36.6479C46.0507 36.1959 46.4802 35.9925 47 35.9925C47.5424 35.9925 47.9492 36.1959 48.469 36.6479L54.6841 42.3658C55.0683 42.7274 55.2943 43.1342 55.2943 43.6766C55.2943 44.6484 54.5485 45.3265 53.5541 45.3265C53.0795 45.3265 52.6275 45.1004 52.2885 44.7614L50.4352 42.8856L48.7402 41.1228L48.8984 44.4902V51.9484C48.8984 52.9654 48.0396 53.7564 47 53.7564Z" fill="#A54D9D" /> </svg>
                                    <p>{file.fileName || 'Document Uploaded'}</p>
                                </Stack>
                                :
                                <>
                                    <svg width="94" height="80" viewBox="0 0 94 80" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M65.3461 34.907C75.3381 35.8191 84.2334 37.8319 91.6556 41.9175C101.412 47.288 77.6476 49.3342 76.3445 57.2067C74.9313 65.7447 99.0473 76.4089 85.4704 79.2857C73.2091 81.8837 61.6347 76.7524 49.3758 74.1504C33.6918 70.8215 6.56218 71.1857 0.970253 61.8487C-4.79101 52.229 16.4197 46.5749 29.9358 40.2854C40.6778 35.2868 51.8693 33.6767 65.3461 34.907Z" fill="#F5F5F5" />
                                        <path className={styles.docPath} style={{fill: iconColor ? iconColor : '#0081A3'}} d="M27.5638 37.3937C28.3096 37.2807 29.0328 37.2129 29.8012 37.2129C30.547 37.2129 31.2928 37.2807 32.0612 37.4163V18.3641C32.0612 16.3075 33.1461 15.1549 35.3383 15.1549H44.9209V27.4495C44.9209 30.7266 46.5255 32.3086 49.78 32.3086H61.8938V51.8806C61.8938 53.9598 60.809 55.0898 58.6168 55.0898H44.2655C43.9943 56.7171 43.3841 58.2539 42.5479 59.6099H59.0236C63.8827 59.6099 66.3913 57.0561 66.3913 52.1744V31.6532C66.3913 28.4891 65.9845 27.0427 64.0183 25.0312L52.1982 13.0304C50.2772 11.0868 48.6952 10.6348 45.8475 10.6348H34.9315C30.095 10.6348 27.5638 13.1886 27.5638 18.0929V37.3937ZM48.8534 27.0201V15.7425L61.2836 28.3535H50.2094C49.2602 28.3535 48.8534 27.9467 48.8534 27.0201ZM29.8238 63.7232C36.1519 63.7232 41.463 58.4347 41.463 52.0614C41.463 45.6881 36.1971 40.4222 29.8238 40.4222C23.4505 40.4222 18.1846 45.6881 18.1846 52.0614C18.1846 58.4573 23.4505 63.7232 29.8238 63.7232ZM22.4335 52.0614C22.4335 51.1348 23.0663 50.5246 23.9929 50.5246H28.2644V46.2531C28.2644 45.3265 28.8746 44.6936 29.8238 44.6936C30.773 44.6936 31.3832 45.3265 31.3832 46.2531V50.5246H35.6547C36.5813 50.5246 37.1915 51.1348 37.1915 52.0614C37.1915 53.0106 36.5813 53.6208 35.6547 53.6208H31.3832V57.9149C31.3832 58.8415 30.773 59.4743 29.8238 59.4743C28.8746 59.4743 28.2644 58.8415 28.2644 57.9149V53.6208H23.9929C23.0663 53.6208 22.4335 53.0106 22.4335 52.0614Z" /> </svg>
                                    <p>Click to choose file or drag and drop</p>
                                    <small>Max file size: {(fileSizeLimitMb || 1).toString()}MB{extensions ? ` | ${ext?.join(', ').toLocaleUpperCase()} Only`: ''}</small>
                                    {fileSizeError ? <small style={{color: styles.destructiveRed500}}>Size limit Exceeded</small>: null}
                                    {fileTypeError ? <small style={{color: styles.destructiveRed500}}>File type not supported</small>: null}
                                </>

                        }
                    </Stack>
                </label>
                {dragActive && (
                    <div
                        id='drag-file-element'
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                    ></div>
                )}
            </form>
        </>
    )
};

export default FileUpload;