import { DashboardTabNames, TabNames } from "./type";
import { GUIDE_TYPES } from "./type";

const descriptions_routers = {
  [`/`]:
    "Casual Relief Teacher smartphone app | Simplifying the CRT booking process.",
  [`/${TabNames.TEACHERS}`]:
    "Spark ensures our Casual Relief Teachers are paid what they would typically receive if they were employed directly by a school.",
  [`/${TabNames.TEACHERS}/vic`]:
    "Find flexible CRT work anywhere in Victoria with Spark Relief Teachers.",
  [`/${TabNames.TEACHERS}/nsw`]:
    "Join Spark as a casual teacher and earn above the government rate in New South Wales.",

  [`/${TabNames.SCHOOLS}`]:
    "We improve teacher visibility for schools, whilst also cutting down admin time for individual school staff members. Check out more information here.",
  [`/${TabNames.SCHOOLS}/vic`]:
    "Find affordable CRT cover at your fingertips with the Spark Relief Teachers booking app.",
  [`/${TabNames.SCHOOLS}/nsw`]:
    "Source casual teachers without the extra admin through the Spark Relief Teachers booking app.",

  [`/${TabNames.ABOUT}`]:
    "Find out more about Spark Relief Teachers.",
  [`/${TabNames.HELP_DESK}`]: "Need help? You can ask a question to our support team here.",
  [`/${TabNames.TERMS_OF_SERVICE}`]: "View Spark Relief Teacher's Terms of Registration here.",
  [`/${TabNames.TEACHER_REFERAL_PROGRAM}`]:
    '',
  [`/${TabNames.BlogsHomePage}/renewing-your-vit`]:
    "Having trouble with renewing your VIT? Not sure what you need to do it? Check out all the information you need right here! ",
  [`/${TabNames.BlogsHomePage}`]:
    "Get all the latest from the Spark blog including Spark app updates, casual relief teacher resources, and tips and tricks to navigating the classroom.",
  [`/${TabNames.BlogsHomePage}/free-resources`]:
    "Accessible resources for teachers and CRTs that make teaching just that little bit easier!",
  [`/${TabNames.BlogsHomePage}/teacherburnout`]:
    "Casual Relief Teachers deal with teacher burnout just as classroom teachers do. Check out the ways we’ve found to help deal with anxiety and stress at work.",
  [`/${TabNames.BlogsHomePage}/pay-increase`]:
    "From the 11th of July 2022, Spark Relief Teachers will be paying $401.12 plus superannuation for every completed placement. ",
  [`/${TabNames.BlogsHomePage}/relief-teacher-bag-essentials`]:
    "We’ve compiled a list of essential items to help make your relief teaching day that little bit easier! ",
  [`/${TabNames.BlogsHomePage}/permission-to-teach`]:
    "Here’s a rundown on all the things final year pre-service teachers (PSTs) need to know about the application process for Permission to Teach. ",
  // [`/${TabNames.OLD_PROJECT_URL}/login`]:
  //   "Sign in to your Spark Relief Teachers account here.",
  // [`/${TabNames.OLD_PROJECT_URL}/register`]:
  //   "Register your Spark Relief Teachers account here.",
  [`/${TabNames.GUIDES}/${GUIDE_TYPES.SCHOOL_GUIDES}`]:
    "Onboarding School Guides for our Schools to help them in using the Spark Relief Teacher App.",
  [`/${TabNames.GUIDES}/${GUIDE_TYPES.TEACHER_GUIDES}`]:
    "Onboarding Teacher Guides for our Teachers to help them in using the Spark Relief Teacher App.",
  [`/${TabNames.SPARK_USER_BENEFITS}`]:
    "Check out the perks and discounts you can receive as a Spark user here!",
  [`/${TabNames.ProfessionalLearningResourcePage}`]:
    "Looking to complete your professional learning requirements this year? Browse through courses and webinars.",
  [`/${TabNames.SHOP}`]:
    "Check out our official Spark Relief Teacher merchandise for your day-to-day casual relief teaching needs!",
  
  [`/${TabNames.DASHBOARD}`]: "Manage your account here",
  [`/${TabNames.DASHBOARD}/${DashboardTabNames.REVIEW}`]: "Review your information before submission",
  [`/${TabNames.DASHBOARD}/${DashboardTabNames.MY_ACCOUNT}`]: "Access your account here!",
  [`/${TabNames.DASHBOARD}/${DashboardTabNames.SETTINGS}`]: "Update your basic information",
  [`/${TabNames.DASHBOARD}/${DashboardTabNames.HELP_DESK}`]: "Submit questions or any feedback here",
};

export default descriptions_routers;
