import { Box, Fade, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import pxToRem from "../../../../helpers/pxToRem";
import styles from "../index.module.scss";
import PhotoUpload from "../../../../components/FileUpload/PhotoUpload";

import sampleProfile from "../../../../assets/images/teacher-registration/sample-profile-photo.png";
import { RegModalWrapper } from "../ModalContent";
import { getIncompleteState } from "../../ReviewPage/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { FileType } from "../../../../components/FileUpload";
import { documentUploadHandler } from "./helper";
import { setErrorMsg } from "../../../../redux/uiSlice";
import { FieldState } from "../../../../firebase/types-teacher";
import { RegFileUploadLabel } from "../../../../components/FileUpload/RegUploadComponents";


const ProfilePhoto = () => {
    const dispatch = useDispatch();
    const userId = useSelector((state: RootState) => state.user.userId);
    const reg = useSelector((state: RootState) => state.userReg.fields);

    const [file, setFile] = useState<FileType | undefined>(reg.profilePicture?.value ? { file: reg.profilePicture?.value } : undefined);
    const onFileChange = (file: File) => {
        setFile({ file: file, fileName: file.name });
    }

    const isAllComplete = () => {
        return file !== undefined
    }

    const handleUpdate = async () => {
        try {
            if (!userId) { throw new Error("User is not authenticated. Please refresh and try again.") };
            let updateData: UserUpdate = {};

            const { fileUrl } = await documentUploadHandler(userId, file, { url: reg.profilePicture?.value }) // TODO: Add type and name here

            if (!fileUrl) {
                updateData = {
                    teacher: { path: '/', data: { profilePicture: null } },
                    user: { path: '/', data: { profilePictureURL: null } }
                }
            } else {
                updateData = {
                    teacher: { path: '/profilePicture', data: { value: fileUrl, state: FieldState.SUBMITTED } },
                    user: { path: '/', data: { profilePictureURL: fileUrl } }
                }
            }
            dispatch(updateFields(updateData));
        } catch (error: any) {
            dispatch(setErrorMsg(error.message));
        }
    };

    useEffect(() => {
        reg.profilePicture?.value ? setFile({file: reg.profilePicture?.value}) : setFile(undefined);
    }, [reg.profilePicture?.value])

    return (
        <>
            <RegModalWrapper title="Profile Photo" handleUpdate={handleUpdate}
                state={getIncompleteState({
                    profilePicture: reg.profilePicture,
                })} isAllComplete={isAllComplete()}
            >
                <Stack gap={pxToRem(40)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >Upload a profile photo</h5>
                        <p className={styles.p}>This image will be printed onto your Spark ID once your account is approved.</p>
                    </Stack>
                    <Stack className={styles.profileContainer}>
                        <Box className={styles['profilePhoto--left']}>
                            <PhotoUpload file={file} onChangeCallback={onFileChange} crop />
                            <Fade in={file !== undefined}>
                                <div> <RegFileUploadLabel file={file} reset={() => setFile(undefined)} text={<small></small>} /> </div>
                            </Fade>
                        </Box>
                        <Stack className={styles['profilePhoto--right']}>
                            <img src={sampleProfile} alt={'Sample profile'} />
                            <Stack>
                                <p className={styles.p}>TIPS</p>
                                <ol style={{ color: styles.shades100 }}>
                                    <li>The background must be neutral</li>
                                    <li>No filters</li>
                                    <li>Make sure you're looking directly at the camera</li>
                                    <li>Feel free to smile!</li>
                                </ol>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </RegModalWrapper>
        </>
    )
}

export default ProfilePhoto;