import { FileType, MultiFileType } from "../../../../components/FileUpload";
import { uploadFileToStorageWithProgress } from "../../../../firebase/general-apis";
import { getUserDocStoragePath } from "../../../../firebase/helpers";
import moment from "moment";
import { AccreditationDocument, AccreditationRequiredField, OtherDocument } from "../../../../redux/types";
import { Dayjs } from "dayjs";

export const primaryIdentity = [
    { value: "passport", label: "Passport" },
    { value: "birthCertificate", label: "Birth Certificate" },
    { value: "australianCitizenshipCertificate", label: "Australian Citizenship Certificate" }
]

export const secondaryIdentity = [
    ...primaryIdentity,
    { value: "driversLicence", label: "Drivers Licence" },
    // {value: "marriageCertificate", label: "Marriage Certificate"},
    { value: "governmentEmployeeIdentityCard", label: "Government Employee Identity Card" },
    { value: "workingWithChildrens", label: "Working with Childrens" }
]

export const WORKING_RIGHTS = [
    { value: "unrestrictedCitizen", label: "Australian Citizen" },
    { value: "unrestrictedPermanentResident", label: "Permanent Resident" },
    { value: "temporaryVisaHolder", label: "Temporary Working Visa" }
]

export const formatContactNumber = (contact: string) => {
    if (/^0[0-9]{3} [0-9]{3} [0-9]{3}$/.test(contact)) {
        return contact;
    }
    let updatedContact = contact;
    if (updatedContact.indexOf(' ') !== 4) {
        updatedContact = updatedContact.substring(0, 4) + ' ' + updatedContact.substring(4);
    }
    if (updatedContact.substring(5).indexOf(' ') !== 3) {
        updatedContact = updatedContact.substring(0, 8) + ' ' + updatedContact.substring(8);
    }
    return updatedContact;
}

export const formatOnChange = (value: string) => {
    // Remove all non-digit characters
    value = value.replace(/\D/g, '');

    // Split the value into chunks based on the desired format
    if (value.length > 4 && value.length <= 7) {
        value = value.replace(/(\d{4})(\d+)/, '$1 $2');
    } else if (value.length > 7) {
        value = value.replace(/(\d{4})(\d{3})(\d+)/, '$1 $2 $3');
    }

    return value;
};


export const formatLandlineOnChange = (value: string) => {
    // Remove all non-digit characters
    value = value.replace(/\D/g, '');

    // Apply the 2 (with brackets) 4 4 format
    if (value.length > 2 && value.length <= 6) {
        value = value.replace(/(\d{2})(\d+)/, '($1) $2');
    } else if (value.length > 6) {
        value = value.replace(/(\d{2})(\d{4})(\d+)/, '($1) $2 $3');
    } else if (value.length > 2) {
        value = `(${value.substring(0, 2)}) ${value.substring(2)}`;
    } else if (value.length > 0) {
        value = `(${value}`;
    }

    return value;
};


export const documentUploadHandler = async (userId: string, file?: FileType, existing?: { url?: string | null, type?: string | null, docTitle?: string | null }) => {
    let fileUrl: string | null = null;
    let fileType: string | null = null;
    let docTitle: string | null = null;
    if (!file) { return { fileUrl: null, fileType: null, docTitle: null } };
    // Upload new documetn
    if (typeof file.file !== 'string') {
        let tempFileName = file.fileName ? file.fileName.split('.')?.[0] : 'document';
        let tempExtension = file.fileName ? `.${file.fileName.split('.')?.[1]}` : '';
        const uploadRes = await uploadFileToStorageWithProgress({ path: getUserDocStoragePath(userId, `${tempFileName}-${moment().format('DD-MM-YYYY-hh:mm:ss')}${tempExtension}`), file: file.file, onProgress: () => { } })
        if (uploadRes.code === 200) {
            fileUrl = uploadRes.data[1];
            fileType = file.file?.type;
            docTitle = file.file?.name;
        }
    } else {
        fileUrl = existing?.url || null;
        fileType = existing?.type || null; // TODO: Need to update to include doc type here
        docTitle = existing?.docTitle || null // TODO: Need to update this value so that the value reflects new field
    }

    return { fileUrl, fileType, docTitle }
}

export const requiredDateHandler = (accred: AccreditationDocument | undefined, date: Dayjs | null, dateField?: AccreditationRequiredField | null, years?: number) => {
    let datesData = {};
    switch (dateField) {
        case 'registrationDate': {
            if (!date || !date.isValid()) {
                datesData = { expiryDate: null, registrationDate: null }
            } else {
                const formattedDate = date.format("DD-MM-YYYY");
                // if (accred?.registrationDate !== formattedDate) {  -> Commented to update even when values are same to ensure that expiry dates are added!
                    let tempDate: string | null = null;
                    if (years) {
                        tempDate = date.add(years, 'year').format("DD-MM-YYYY") || null;
                    }
                    datesData = { ...datesData, registrationDate: formattedDate, expiryDate: tempDate }
                // }
            }
            break;
        }
        case null: break;
        case 'expiryDate': default: {
            if (!date || !date.isValid()) {
                datesData = { expiryDate: null, registrationDate: null }
            } else {
                const formattedDate = date.format("DD-MM-YYYY");
                // if (accred?.expiryDate !== formattedDate) {
                    datesData = { ...datesData, registrationDate: null, expiryDate: formattedDate }
                // }
            }
        }
    }
    return datesData
}

export const getServiceDocs = (data?: { [id: string]: { value: string, type?: string, docTitle?: string } | string }): MultiFileType[] => {
    return data ? Object.entries(data)
        .filter(([_id, el]) => {
            return typeof el === 'string' ? el !== null : el.value !== undefined
        })
        .map(([id, el]) => {
            if (typeof el === 'string') {
                return { id: id, file: el } as MultiFileType
            }
            return { id: id, file: el.value, fileName: el.docTitle } as MultiFileType
        }) : []
}
export const getEmpDocs = (data?: { [id: string]: OtherDocument }): MultiFileType[] => {
    return data ? Object.entries(data)
        .filter(([_id, el]) => {
            return el.value !== undefined
        })
        .map(([id, el]) => {
            return { id: id, file: el.value, fileName: el.docTitle } as MultiFileType
        }) : []
}

export const handleEmpDocsChange = async (userId: string, data: any | undefined, files: MultiFileType[]) => {
    // Compile list of removed files
    let oldfileIds = Object.keys(data || {})
    let newFileIds = files.map(el => el.id);
    let deletedFiles = oldfileIds.filter(el => !newFileIds.includes(el));

    // Upload any new files
    let newFiles: { [docId: string]: OtherDocument } = {};
    for (let file of files) {
        if (typeof file.file !== 'string') {
            const { fileUrl, fileType, docTitle } = await documentUploadHandler(userId, file);
            newFiles[`${file.id}`] = {
                submitted: 'web',
                type: fileType,
                value: fileUrl,
                docTitle: docTitle
            }
        }
    }

    let newData: any = {};
    // Add new files
    Object.entries(newFiles).forEach(([id, el]) => {
        switch (id) {
            case 'value': {
                newData = { ...newData, value: el.value, type: el.type, }
                break;
            }
            default: {
                newData = {
                    ...newData,
                    [`${id}`]: { submitted: 'web', value: el.value, type: el.type, docTitle: el.docTitle }
                }
            }
        }
    });

    // Set Deleted files to null
    deletedFiles.forEach(id => { newData = { ...newData, [`${id}`]: null } });

    return newData;
}

export const handleServiceDocsChange = async (userId: string, data: any | undefined, files: MultiFileType[]) => {
    // Compile list of removed files
    let oldfileIds = Object.keys(data || {})
    let newFileIds = files.map(el => el.id);
    let deletedFiles = oldfileIds.filter(el => !newFileIds.includes(el));

    // Upload any new files
    let newFiles: { [docId: string]: OtherDocument } = {};
    for (let file of files) {
        if (typeof file.file !== 'string') {
            const { fileUrl, fileType, docTitle } = await documentUploadHandler(userId, file);
            newFiles[`${file.id}`] = {
                type: fileType,
                value: fileUrl,
                docTitle: docTitle
            }
        }
    }

    let newData: any = {};
    // Add new files
    Object.entries(newFiles).forEach(([id, el]) => { newData = { ...newData, [`${id}`]: { value: el.value, type: el.type, docTitle: el.docTitle } } });
    // Set Deleted files to null
    deletedFiles.forEach(id => { newData = { ...newData, [`${id}`]: null } });

    return newData;
}

export const getUploadedFileName = (mimeType?: string, fileName?: string) => {
    let docTitle: string;
    if (fileName) {
        docTitle = fileName
    } else {
        switch (mimeType) {
            case 'image/png': case 'image/jpeg': docTitle = 'Image Uploaded'; break;
            case 'application/pdf': docTitle = 'PDF Uploaded'; break;
            default: docTitle = 'Document Uploaded';
        }
    }
    return docTitle;
}

export interface EmpDoc {
    id: string;
    path: string;
    file: any;
    mimeType?: string;
    fileName?: string;
}