import { InputLabel, Select, MenuItem, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserRegTextField } from "../../../../components/TextField";
import { getErrorMsgForTextInput } from "../../../../helpers/inputValidators";
import pxToRem from "../../../../helpers/pxToRem";
import useTextInput from "../../../../hooks/useTextInput";
import { RootState } from "../../../../redux/store";
// import FileUpload from "./FileUpload";
import FileUpload, { FileType } from "../../../../components/FileUpload";
import styles from "../index.module.scss";
import { RegModalWrapper } from "../ModalContent";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { documentUploadHandler, WORKING_RIGHTS } from "./helper";
import { getIncompleteState } from "../../ReviewPage/helper";
import { DateValidationError } from "@mui/x-date-pickers/models";
import { CustomizedDatePicker } from "../../../../components/DatePicker";
import { TeacherRegFormControl } from "../../../../components/FormControl";
import { setErrorMsg } from "../../../../redux/uiSlice";
import { RegFileUploadLabel } from "../../../../components/FileUpload/RegUploadComponents";
import { updateUserInfo } from "../../../../firebase/general-apis";
dayjs.extend(customParseFormat);

// = ["Australian Citizen", "Permanent Resident", "Temporary Working Visa"];
const WorkingRights = () => {
    const dispatch = useDispatch();
    const userId = useSelector((state: RootState) => state.user.userId);
    const reg = useSelector((state: RootState) => state.userReg.fields);

    const [dateError, setDateError] = useState<DateValidationError | null>(null);

    const [selected, setSelected] = useState<string | undefined>(reg.workingRights?.value);
    const reference = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Document reference") }, defaultValue: reg.workingRights?.reference || "" });
    const [expiryDate, setExpiryDate] = useState<Dayjs | null>(reg.workingRights?.expiry ? dayjs(reg.workingRights?.expiry, "DD-MM-YYYY") : null);

    const [file, setFile] = useState<FileType | undefined>(reg.workingRights?.url ? { file: reg.workingRights?.url, fileName: reg.workingRights?.docTitle } : undefined);

    const errorMessage = useMemo(() => {
        switch (dateError) {
            case 'minDate': {
                return 'Please select a date after today'
            }
            default: return undefined
        }
    }, [dateError]);

    const onFileChange = (file: File) => {
        setFile({ file: file, fileName: file.name });
    }

    // const onUploadSuccess = async (url: string, mimeType: string) => {
    //     if(!userId) {return}
    //     const allComplete = allFieldsDefined(reg.workingRights, ['url', 'type']) && url !== '';
    //     await updateUserInfo(userId, {
    //         teacher: { path: "/workingRights", data:{ 
    //             url: url,
    //             type: mimeType,
    //             state: allComplete?'submitted':'incomplete'
    //         }}
    //     })
    // }

    // const handleDelete = async () => {
    //     if (!userId) { return }
    //     await updateUserInfo(userId, {
    //         teacher: { path: "/workingRights", data: { url: null, type: null, state: 'incomplete' } }
    //     })
    // }

    const isAllComplete = (): boolean => {
        return ['unrestrictedCitizen', 'unrestrictedPermanentResident'].includes(selected || '') || (selected !== undefined && !dateError && expiryDate?.isValid() && !reference.hasError && reference.value.trim() !== '' && file !== undefined) || false
    }
    const isSomeComplete = (): boolean => {
        return selected !== undefined || (!dateError && expiryDate?.isValid()) || reference.value !== "" || file !== undefined
    }


    const handleUpdate = async () => {
        try {
            if (!userId) { throw new Error("User is not authenticated. Please refresh and try again.") };
            // if(!file) { throw new Error("File is not valid. Please try again.")};
            let formattedDate = '';
            if (expiryDate && !dateError && expiryDate.isValid()) { formattedDate = expiryDate.format("DD-MM-YYYY"); }

            let updateData: UserUpdate = {};

            // let fileUrl: string | null = null;
            // let fileType: string | null = null;
            // let docTitle: string | null = null;
            // // Upload new documetn
            // if(typeof file.file !== 'string') {
            //     const uploadRes = await uploadFileToStorageWithProgress({ path: getUserDocStoragePath(userId, file.fileName || ''), file: file.file, onProgress: () => { } })
            //     if(uploadRes.code === 200) {
            //         fileUrl = uploadRes.data[1];
            //         fileType = file.file.type;
            //         docTitle = file.file.name;
            //     }
            // } else {
            //     fileUrl = reg.workingRights?.url || null;
            //     fileType = 'type'  // reg.workingRights?.type || null; // TODO: Need to update to include doc type here
            //     docTitle = 'Document Uploaded' // reg.workingRights.docTitle // TODO: Need to update this value so that the value reflects new field
            // }
            const { fileUrl, fileType, docTitle } = await documentUploadHandler(userId, file, { url: reg.workingRights?.url, type: reg.workingRights?.type, docTitle: reg.workingRights?.docTitle })

            const cleanIdentityDocs = () => {
                if (reg.primaryIdentification?.documentType !== 'passport') { return reg.primaryIdentification?.id; }
            }

            if (reg.workingRights) {
                switch (selected) {
                    case "unrestrictedCitizen": case "unrestrictedPermanentResident":
                        updateData = {
                            teacher: {
                                path: "/",
                                data: { workingRights: { value: selected, state: "submitted" } }
                            }
                        }
                        break;
                    default:
                        updateData = {
                            teacher: {
                                path: "/workingRights",
                                data: {
                                    // workingRights: {
                                        ...(isAllComplete() ? { state: 'submitted' } : isSomeComplete() ? { state: 'incomplete' } : null),
                                        ...(reg.workingRights.value ?
                                            reg.workingRights.value !== selected ? { value: selected ? selected : null } : null
                                            :
                                            { value: selected ? selected : null }
                                        ),
                                        ...(reg.workingRights.reference ?
                                            reg.workingRights.reference !== reference.value ? { reference: !reference.hasError ? reference.value : null } : null
                                            :
                                            { reference: !reference.hasError && reference.value.trim() !== "" ? reference.value : null }
                                        ),
                                        ...(reg.workingRights.expiry ?
                                            reg.workingRights.expiry !== formattedDate ? { expiry: !dateError && formattedDate ? formattedDate : null } : null
                                            :
                                            { expiry: !dateError && formattedDate ? formattedDate : null }
                                        ),
                                        url: fileUrl, type: fileType, docTitle: docTitle
                                    // },
                                },
                            }
                        }
                }
            }

            // Clean any primary document that is not a passport if the user selects temporary resident
            if (selected === 'temporaryVisaHolder') {
                let primaryId = cleanIdentityDocs();
                primaryId !== undefined && await updateUserInfo(userId, {
                    teacher: {
                        path: '/identity',
                        data: {
                            [`${primaryId}`]: null
                        }
                    }
                });
            }

            dispatch(updateFields(updateData));
        } catch (error: any) {
            dispatch(setErrorMsg(error.message));
        }
    }

    useEffect(() => {
        reg.workingRights?.expiry ? setExpiryDate(dayjs(reg.workingRights.expiry, "DD-MM-YYYY")) : setExpiryDate(null);
    }, [reg.workingRights?.expiry])

    useEffect(() => {
        reg.workingRights?.reference ? reference.setValue(reg.workingRights.reference) : reference.reset();
    }, [reg.workingRights?.reference])

    useEffect(() => {
        reg.workingRights?.value ? setSelected(reg.workingRights.value) : setSelected(undefined);
    }, [reg.workingRights?.value]);

    useEffect(() => {
        reg.workingRights?.url ? setFile({ file: reg.workingRights.url, fileName: reg.workingRights?.docTitle }) : setFile(undefined);
    }, [reg.workingRights?.url, reg.workingRights?.docTitle]);



    return (
        <>
            <RegModalWrapper title="Working Rights" handleUpdate={handleUpdate}
                state={getIncompleteState({
                    workingRights: reg.workingRights
                })} isAllComplete={isAllComplete()}
            >
                <Stack gap={pxToRem(40)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >Working Rights</h5>
                        <p className={styles.p}>Select your Australian working rights</p>
                    </Stack>
                    <Stack gap={pxToRem(24)}>
                        <TeacherRegFormControl variant="standard" >
                            <InputLabel>Working Rights</InputLabel>
                            <Select value={selected} onChange={(e) => setSelected(e.target.value)}>
                                {
                                    WORKING_RIGHTS.sort((a, b) => a.label.localeCompare(b.label)).map((el, i) => (
                                        <MenuItem key={i} value={el.value}>{el.label}</MenuItem>
                                    ))
                                }
                            </Select>
                        </TeacherRegFormControl>
                        {
                            selected !== undefined && !['unrestrictedCitizen', 'unrestrictedPermanentResident'].includes(selected) ?
                                <>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        {/* <DemoContainer components={['DatePicker']}> */}
                                        <CustomizedDatePicker value={expiryDate} onChange={(e) => setExpiryDate(e)} minDate={dayjs()} label="Expiry Date" format="DD/MM/YYYY"
                                            onError={(newError) => setDateError(newError)}
                                            slotProps={{
                                                textField: {
                                                    helperText: errorMessage,
                                                }
                                            }}
                                        />
                                        {/* </DemoContainer> */}
                                    </LocalizationProvider>
                                    <UserRegTextField
                                        value={reference.value}
                                        onChange={(e) => reference.setValue(e.target.value)}
                                        error={reference.hasTouched && reference.hasError} helperText={reference.getErrorMessage()}
                                        label="Document Reference"
                                    />
                                    <RegFileUploadLabel file={file} reset={() => setFile(undefined)} />
                                    <FileUpload file={file} onChangeCallback={onFileChange} extensions={'document'} iconColor={styles.primaryYellow500} />
                                </>
                                : null
                        }
                    </Stack>
                </Stack>
            </RegModalWrapper>
        </>
    );
};

export default WorkingRights;