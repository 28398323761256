export enum TeacherAccountState {
    INCOMPLETE = "incomplete",
    SUBMITTED = "submitted",
    APPROVED = "approved",
    REJECTED = "rejected",
    ONHOLD = "onHold",
    DELETED = "deleted"
}

export enum FieldState {
    INCOMPLETE = "incomplete",
    SUBMITTED = "submitted",
    APPROVED = "approved",
    REJECTED = "rejected",
    ONHOLD = "onHold",
    EXPIRED = 'expired'
}

export enum TeacherAvailabilityStateForDate {
    unavailable='unavailable',
    available='available',
    booked='booked',
}

export type TeacherAvailability = {
    [date: string]: string;
}

export enum TeacherPlacementState {
    confirmed='confirmed',
    enRoute='enRoute',
    nearby='nearby',
    inProgress='inProgress',
    earlyCheckout='earlyCheckout',
    completed='completed',
    cancelled='cancelled'
}

export enum ConfirmedPlacementState {
    confirmed='confirmed',
    cancelledBySchool='cancelledBySchool',
    cancelledByTeacher='cancelledByTeacher'
}

export type TeacherPlacement = {
    date?: string;
    notified?: number;
    schoolUid?: string;
}

export type TeacherPlacementKeys = {
    [year: string]: {
        [date: string]: {
            [placementKey: string]: TeacherPlacement;
        }
    }
}

export enum NSWAccreditationLevel {
    BAND1='band1',
    BAND2='band2',
    BAND3='band3',
    UNSURE=`I'm Unsure`
}


export const ECCQualificationLevels = ['certThree', 'dimploma', 'earlyChildhoodTrained', 'other', 'noFormalTraining']

export const ECCQualToTitle = (qual?: string|null) => {
    switch (qual) {
        case 'certThree': return 'Certificate III';
        case 'dimploma': return 'Diploma';
        case 'earlyChildhoodTrained': return 'Early Childhood Trained';
        case 'other': return 'Other';
        case 'noFormalTraining': return 'No Formal Training';
        default: return '-'
    }
}