import { useEffect, useRef } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";


export const TeacherStatePageWrapper = ({children}: any) => {

    const ref = useRef<HTMLDivElement>(null);

    return (
        <div ref={ref}>
            <Header/>
            {children}
        </div>
    )
}

export const SchoolStatePageWrapper = ({children}: any) => {

    const ref = useRef<HTMLDivElement>(null);

    return (
        <div ref={ref}>
          <Header />
          {children}
          <Footer teacherSchool/>
        </div>
      );
}