import { Box } from "@mui/material";
import { AccreditationDocument, AccreditationDocumentDetails, AccreditationRequiredField, LocalTeacherEmploymentSetting, REG_STEP, TeacherFields, UserType } from "../../../redux/types";
import { secondaryIdentity, WORKING_RIGHTS } from "../RegistrationModals/components/helper";
import { isObjectEmpty } from "../../../firebase/general-apis";
import styles from "../ReviewPage/index.module.scss";
import { ECCQualificationLevels, ECCQualToTitle, FieldState, TeacherAccountState } from "../../../firebase/types-teacher";
import moment from "moment";


export interface accredDocParam {
    school: AccreditationDocumentDetails[] | null;
    earlyChildcare: AccreditationDocumentDetails[] | null; 
}

// export const getReviewGroups = (fields: TeacherFields, docs: { school?: AccreditationDocumentDetails[] | null, ecc?: AccreditationDocumentDetails[] | null }, state?: string[]): { title: string, step: string, data: any[], state: string, expired?: boolean }[] => {
export const getReviewGroups = (fields: TeacherFields, user: UserType, docs: accredDocParam, employmentSetting: LocalTeacherEmploymentSetting, locationState: string, state?: string[]): { title: string, step: string, data: any[], state: string, expired?: boolean }[] => {
    const namesState = getIncompleteState({ firstName: fields.firstName, lastName: fields.lastName })
    const contactState = getIncompleteState({ contact: fields.contact, homeAddress: fields.homeAddress })
    const basicDetailsState = getCombinedState([namesState, contactState]);

    const profilePhotoState = getIncompleteState({ profilePicture: fields.profilePicture });
    const workingRightsState = getIncompleteState({ workingRights: fields.workingRights })

    const primaryIdentityState = getIncompleteState({ primaryIdentification: fields.primaryIdentification });
    const secondaryIdentityState = getIncompleteState({ secondaryIdentification: fields.secondaryIdentification });
    const holdingIDState = getIncompleteState({ holdingIdPhotoURL: fields.holdingIdPhotoURL });

    const birthDateError = fields.primaryIdentification?.birthDate && fields.secondaryIdentification?.birthDate ? fields.primaryIdentification.birthDate === fields.secondaryIdentification.birthDate : true;
    const identityState = getCombinedState([primaryIdentityState, secondaryIdentityState, holdingIDState,
        ...(!birthDateError ? [FieldState.INCOMPLETE] : [])
    ]);

    let schoolTeachingDocs: { [type: string]: AccreditationDocument | undefined } = {}
    let schoolTeachingDocsStates: FieldState[] = [];
    if (docs.school && ['school', 'both'].includes(employmentSetting)) {
        for (let i of docs.school || []) {
            schoolTeachingDocs = {
                ...schoolTeachingDocs,
                [`${i.type}`]: fields.accreditation?.['school']?.[`${locationState}`]?.[`${i.type}`]
            }
            if (!i.optional) {
                schoolTeachingDocsStates = [...schoolTeachingDocsStates, (fields.accreditation?.['school']?.[`${locationState}`]?.[`${i.type}`]?.state || FieldState.INCOMPLETE) as FieldState]
            }
        }
    }
    let eccTeachingDocs: { [type: string]: AccreditationDocument | undefined } = {}
    let eccTeachingDocsStates: FieldState[] = [];
    if (['earlyChildcare', 'both'].includes(employmentSetting)) {
        for (let i of docs.earlyChildcare || []) {
            eccTeachingDocs = {
                ...eccTeachingDocs,
                [`${i.type}`]: fields.accreditation?.['earlyChildcare']?.[`${fields.setLocation?.homeState}`]?.[`${i.type}`]
            }
            if (!i.optional) {
                eccTeachingDocsStates = [...eccTeachingDocsStates, (fields.accreditation?.['earlyChildcare']?.[`${fields.setLocation?.homeState}`]?.[`${i.type}`]?.state || FieldState.INCOMPLETE) as FieldState]
            }
        }
    }

    // const schoolTeachingDocsState = schoolTeachingDocsStates.length === 0 ? FieldState.SUBMITTED : 
    const schoolTeachingDocsState = Object.values(schoolTeachingDocs).length === 0 ? FieldState.SUBMITTED : getCombinedState(schoolTeachingDocsStates);
    // const eccTeachingDocsState = Object.values(eccTeachingDocs).length === 0 ? FieldState.SUBMITTED : getCombinedState(Object.values(eccTeachingDocs).map(el => el?.state || FieldState.INCOMPLETE))
    const eccTeachingDocsState = Object.values(eccTeachingDocsStates).length === 0 ? FieldState.SUBMITTED : getCombinedState(eccTeachingDocsStates);

    const referenceState = getIncompleteState({ reference: fields.reference, })
    const emergencyState = getIncompleteState({ firstEmergencyContact: fields.firstEmergencyContact, secondEmergencyContact: fields.secondEmergencyContact, });
    const primaryContactState = getIncompleteState({ firstEmergencyContact: fields.firstEmergencyContact });
    const secondaryContactState = getIncompleteState({ secondEmergencyContact: fields.secondEmergencyContact });

    const bankState = getIncompleteState({ bank: fields.bank })
    const superState = getIncompleteState({ super: fields.super })
    const taxState = getIncompleteState({ tax: fields.tax });

    const accredLevelState = fields.service && Object.keys(fields.service).length > 0 ? FieldState.SUBMITTED : FieldState.INCOMPLETE;

    const temp = [
        (!state || state.length === 0 || state.includes(basicDetailsState) ?
            {
                title: 'Basic Details',
                step: REG_STEP.NAMES,
                data: [
                    { field: 'Names', state: namesState as FieldState, step: REG_STEP.NAMES },
                    { field: 'First Name', value: fields.firstName?.value || user.firstName},
                    { field: 'Last Name', value: fields.lastName?.value || user.lastName},
                    { field: 'Preferred Name', value: fields.preferredName?.value || user.preferredName ||  '-' },
                    { field: 'Contact Information', state: contactState as FieldState, step: REG_STEP.CONTACT },
                    { field: 'Email', value: fields.email?.value || user.email },
                    { field: 'Contact Number', value: fields.contact?.value },
                    { field: 'Address', value: fields.homeAddress?.address },
                ],
                state: basicDetailsState,
            } : null),
        (!state || state.length === 0 || state.includes(profilePhotoState) ?
            {
                title: 'Profile Photo',
                step: REG_STEP.PROFILE_PHOTO,
                data: [
                    { field: 'Profile Photo', state: profilePhotoState as FieldState, step: REG_STEP.PROFILE_PHOTO },
                    { field: '', value: fields.profilePicture && fields.profilePicture.value ? <Box className={styles.imgContainer}> <img src={fields.profilePicture?.value} alt={"Uploaded user profile"} /> </Box> : <p style={{ textAlign: 'right' }}>Not uploaded</p> }
                ],
                state: profilePhotoState
            } : null),
        (!state || state.length === 0 || state.includes(workingRightsState) ?
            {
                title: 'Working Rights',
                step: REG_STEP.WORKING_RIGHTS,
                data: [
                    { field: 'Document', state: workingRightsState as FieldState, step: REG_STEP.WORKING_RIGHTS },
                    { field: 'Working Rights', value: renameRights(fields.workingRights?.value) },
                    ...(wrDocs(fields.workingRights?.value) ? [{ field: 'Expiry Date', value: fields.workingRights?.expiry }] : []),
                    ...(wrDocs(fields.workingRights?.value) ? [{ field: 'Document Reference', value: fields.workingRights?.reference }] : []),
                    ...(wrDocs(fields.workingRights?.value) ? [{ field: 'Document Uploaded YES/NO', value: fields.workingRights?.url ? 'YES' : 'NO' }] : []),
                ],
                state: workingRightsState
            } : null),
        (!state || state.length === 0 || state.includes(identityState) ?
            {
                title: 'Identity Verification',
                step: REG_STEP.PRIMARY_DOC,
                data: [
                    { field: 'Primary Identification', state: primaryIdentityState as FieldState, step: REG_STEP.PRIMARY_DOC },
                    { field: 'Primary Identification', value: renameDoc(fields.primaryIdentification?.documentType) },
                    { field: 'Country of Issue', value: fields.primaryIdentification?.country },
                    { field: 'Name on Document', value: fields.primaryIdentification?.name },
                    { field: 'Date of Birth', value: <span style={{color: !birthDateError ? styles.destructiveRed500 : styles.shades100}}>{fields.primaryIdentification?.birthDate}</span>},
                    { field: 'Document Number', value: fields.primaryIdentification?.reference },
                    { field: 'Document Uploaded YES/NO', value: fields.primaryIdentification?.value ? 'YES' : 'NO' },

                    { field: 'Secondary Identification', state: secondaryIdentityState as FieldState, step: REG_STEP.SECONDARY_DOC },
                    { field: 'Secondary Identification', value: renameDoc(fields.secondaryIdentification?.documentType) },
                    { field: 'Country of Issue', value: fields.secondaryIdentification?.country },
                    { field: 'Name on Document', value: fields.secondaryIdentification?.name },
                    { field: 'Date of Birth', value: <span style={{color: !birthDateError ? styles.destructiveRed500 : styles.shades100}}>{fields.secondaryIdentification?.birthDate}</span> },
                    { field: 'Document Number', value: fields.secondaryIdentification?.reference },
                    { field: 'Document Uploaded YES/NO', value: fields.secondaryIdentification?.value ? 'YES' : 'NO' },

                    { field: 'Photo Holding ID', state: holdingIDState as FieldState, step: REG_STEP.HOLDING_ID },
                    { field: 'Document Uploaded YES/NO', value: fields.holdingIdPhotoURL?.value ? 'YES' : 'NO' },
                ],
                state: identityState
            } : null),
        ((!state || state.length === 0 || state.includes(schoolTeachingDocsState)) && ['school', 'both'].includes(employmentSetting) && docs.school !== undefined ?
            {
                title: 'Teaching Documentation',
                step: docs.school && docs.school.length > 0 ? docs.school[0].type : REG_STEP.TEACHING_DOCS,
                data: [
                    // ...(docs.school.length === 0 ?
                    ...(docs.school === null ?
                        [{ field: 'We currently do not operate in this state' }]
                        :
                        docs.school.length === 0 ?
                            [{ field: 'No documents are required for this state' }]
                            :
                            docs.school.flatMap(el => {
                                let accred = fields.accreditation?.school?.[`${locationState}`]?.[`${el.type}`];
                                return [
                                    {
                                        field: el.optional ? `(Optional) ${el.short_title}` : el.short_title,
                                        state: accred?.state || FieldState.INCOMPLETE,
                                        step: el.type,
                                        expired: accred?.expiryDate ?
                                            moment(accred.expiryDate, "DD-MM-YYYY").isBefore(moment())
                                            : false
                                    },
                                    ...(getAccredDocFields(accred, el.required_datefield)),
                                    { field: 'Document Reference', value: accred?.reference },
                                    { field: 'Document Uploaded YES/NO', value: accred?.imageURL ? 'YES' : 'NO' },
                                ]
                            }))
                ],
                state: schoolTeachingDocsState,
                expired: docs.school?.length === 0 ? undefined :
                    docs.school?.filter(el => !el.optional).some(el => isExpired(fields.accreditation?.school?.[`${locationState}`]?.[`${el.type}`], el.required_datefield, el.renewal_frequency_years) )
            } : null),
        ((!state || state.length === 0 || state.includes(eccTeachingDocsState)) && ['earlyChildcare', 'both'].includes(employmentSetting) && docs.earlyChildcare !== undefined ?
            {
                title: 'Early Childcare Documentation',
                step: docs.earlyChildcare && docs.earlyChildcare.length > 0 ? docs.earlyChildcare[0].type : REG_STEP.TEACHING_DOCS,
                data: [
                    // ...(docs.ecc.length === 0 ?
                    ...(docs.earlyChildcare === null ?
                        [{ field: 'We currently do not operate in this state' }]
                        :
                        docs.earlyChildcare.length === 0 ?
                            [{ field: 'No documents are required for this state' }]
                            :
                            docs.earlyChildcare.flatMap(el => {
                                let accred = fields.accreditation?.earlyChildcare?.[`${locationState}`]?.[`${el.type}`]
                                return [
                                    {
                                        // field: el.short_title,
                                        field: el.optional ? `(Optional) ${el.short_title}` : el.short_title,
                                        state: accred?.state || FieldState.INCOMPLETE,
                                        step: el.type,
                                        expired: accred?.expiryDate ?
                                            moment(accred?.expiryDate, "DD-MM-YYYY").isBefore(moment())
                                            : false
                                    },
                                    ...(getAccredDocFields(accred, el.required_datefield)),
                                    ...(el.type === 'earlyChildcareQualification'?
                                        [
                                            {field: 'Qualification', value: ECCQualToTitle(accred?.qualification)}
                                        ] : []
                                    ),
                                    { field: 'Document Reference', value: accred?.reference },
                                    { field: 'Document Uploaded YES/NO', value: accred?.imageURL ? 'YES' : 'NO' },
                                ]
                            }))
                ],
                state: eccTeachingDocsState,
                expired: docs.earlyChildcare?.length === 0 ? undefined :
                    docs.earlyChildcare?.filter(el => !el.optional).some(el => isExpired(fields.accreditation?.earlyChildcare?.[`${locationState}`]?.[`${el.type}`], el.required_datefield, el.renewal_frequency_years) )
            } : null),
        (!state || state.length === 0 || state.includes(referenceState) ?
            {
                title: 'Reference',
                step: REG_STEP.REFERENCE,
                data: [
                    { field: 'Reference', state: referenceState as FieldState, step: REG_STEP.REFERENCE },
                    ...(
                        fields.reference?.requestedInterview ?
                            [
                                { field: 'Requested for interview', value: ' ' },
                            ]
                            :
                            [
                                { field: 'First Name', value: fields.reference?.firstName },
                                { field: 'Last Name', value: fields.reference?.lastName },
                                { field: 'Email', value: fields.reference?.email }
                            ]
                    ),
                ],
                state: referenceState
            } : null),
        (!state || state.length === 0 || state.includes(emergencyState) ?
            {
                title: 'Emergency Contacts',
                step: REG_STEP.EMERGENCY_CONTACTS,
                data: [
                    { field: 'Primary Contact', state: primaryContactState, step: REG_STEP.EMERGENCY_CONTACTS },
                    { field: 'Name', value: fields.firstEmergencyContact?.name },
                    { field: 'Relationship', value: fields.firstEmergencyContact?.relationship },
                    { field: 'Contact', value: fields.firstEmergencyContact?.contact },
                    { field: 'Secondary Contact', state: secondaryContactState, step: REG_STEP.EMERGENCY_CONTACTS },
                    { field: 'Name', value: fields.secondEmergencyContact?.name },
                    { field: 'Relationship', value: fields.secondEmergencyContact?.relationship },
                    { field: 'Contact', value: fields.secondEmergencyContact?.contact },
                ],
                state: emergencyState
            } : null),

        ((!state || state.length === 0 || state.includes(accredLevelState)) && (locationState === "NSW" && ['school', 'both'].includes(employmentSetting) &&  ![TeacherAccountState.APPROVED, TeacherAccountState.ONHOLD].includes(fields.accountState?.value as TeacherAccountState)) ?
            {
                title: 'Accreditation Level',
                step: REG_STEP.ACCREDITATION_LEVEL,
                data: [
                    { field: 'Casual Teacher Band', state: accredLevelState, step: REG_STEP.ACCREDITATION_LEVEL },
                    { field: 'Statement of Service Uploaded (YES/NO)', value: fields.service && !isObjectEmpty(fields.service) ? 'YES' : 'NO' },
                ],
                state: accredLevelState
            } : null),

        (!state || state.length === 0 || state.includes(bankState) ?
            {
                title: 'Bank Details',
                step: REG_STEP.BANK,
                data: [
                    
                    { field: 'Bank Details', state: bankState as FieldState, step: REG_STEP.BANK },
                    { field: 'Account Name', value: fields.bank?.accountName },
                    { field: 'BSB Number', value: fields.bank?.bsb },
                    { field: 'Account Number', value: fields.bank?.accountNumber },
                    ...(
                        fields.bank?.value ? 
                        [{ field: 'Document Uploaded YES/NO', value: fields.bank?.value ? 'YES' : 'NO' }]
                        :[]
                    )
                ],
                state: bankState
            } : null),
        (!state || state.length === 0 || state.includes(superState) ?
            {
                title: 'Superannuation',
                step: REG_STEP.SUPER,
                data: [
                    { field: 'Superannuation', state: superState as FieldState, step: REG_STEP.SUPER },
                    { field: 'Document Uploaded YES/NO', value: fields.super?.docs && !isObjectEmpty(fields.super?.docs) ? 'YES' : 'NO' },
                ],
                state: superState
            } : null),
        (!state || state.length === 0 || state.includes(taxState) ?
            {
                title: 'Tax File Number Declaration',
                step: REG_STEP.TAX,
                data: [
                    { field: 'Tax File Number Declaration', state: taxState as FieldState, step: REG_STEP.TAX },
                    { field: 'Document Uploaded YES/NO', value: fields.tax?.docs && !isObjectEmpty(fields.tax?.docs) ? 'YES' : 'NO' },
                ],
                state: taxState
            } : null),
    ].filter(group => group !== null);

    // {
    //     title: 'Pre-employment Declaration',
    //     data: [
    //         (fields.employmentDeclarations && fields.employmentDeclarations.VIC ? {field: 'Declaration', value: fields.employmentDeclarations.VIC.hasLimitations ? "Has Employment Limitations" : "No Employment Limitations"}: null),
    //         (fields.employmentDeclarations && fields.employmentDeclarations.VIC ? {field: 'Time', value: fields.employmentDeclarations.VIC.timeDeclared ? moment(fields.employmentDeclarations.VIC.timeDeclared).format("dddd DD MMMM YYYY, hh:mm a") : ''} : null),
    //     ]
    // }
    return temp as { title: string, step: string, data: any[], state: string, expired?: boolean }[];
}

const renameRights = (rights?: string) => {
    if (!rights) { return '-' }
    return WORKING_RIGHTS.filter(el => el.value === rights)[0].label;
}

const wrDocs = (rights?: string) => {
    return ['temporaryVisaHolder'].includes(rights || '');
}

const renameDoc = (doc?: string) => {
    if (!doc) { return '-' }
    const docMatch = secondaryIdentity.filter(el => el.value == doc)[0]
    return docMatch ? docMatch.label : '-'
    // return secondaryIdentity.filter(el => el.value == doc )[0].label;
}

export const getIncompleteState = (fields: TeacherFields) => {

    const match = Object.entries(fields).filter(el => {
        if (el[0] === "employmentDeclarations") {
            return null;
        }
        if (el[1] && el[1].state && el[1].state === 'rejected') {
            return true;
        }
        return false;
    })[0]
    if (match) {
        return 'rejected'
    }

    // At this point it is not rejected
    // if there is any incomplete, then it is incomplete
    // Then if there are any submitted, then submitted,
    // Finally it should be approved

    const isIncomplete = Object.entries(fields).filter(([key, value]) => {
        if (key === "employmentDeclarations" || key === "preferredName" || key === "bank" || key === "super" || key === 'tax') {
            return null;
        }
        if (value && value.state && value.state === 'incomplete') {
            return true;
        }
        if (key === 'service' && isObjectEmpty(value)) {
            return true;
        }
        return false;
    })[0]

    // Account is incomplete if the birthdates do not match
    const birthDateSame = fields.primaryIdentification?.birthDate && fields.secondaryIdentification?.birthDate ? fields.primaryIdentification.birthDate === fields.secondaryIdentification.birthDate : true;
    if (isIncomplete || !birthDateSame) {
        return 'incomplete'
    }

    // If there are any fields that are missing, 

    const isSubmitted = Object.entries(fields).filter(([key, value]) => {
        if (key === "employmentDeclarations" || key === "preferredName") {
            return null;
        }
        if (value && value.state && value.state === 'submitted') {
            return true;
        }
        return false;
    })[0]
    if (isSubmitted) {
        return 'submitted'
    }

    const isApproved = Object.entries(fields).filter(([key, value]) => {
        if (key === "employmentDeclarations" || key === "preferredName") {
            return null;
        }
        if (value && value.state && value.state === 'approved') {
            return true;
        }
        return false;
    })[0]
    if (isApproved) {
        return 'approved'
    }

    return 'incomplete'
}

export const getCombinedState = (states: string[]) => {
    if (states.includes(FieldState.REJECTED.valueOf())) {
        return FieldState.REJECTED
    } else if (states.includes(FieldState.INCOMPLETE.valueOf()) || states.includes(FieldState.EXPIRED.valueOf())) {
        return FieldState.INCOMPLETE
    } else if (states.includes(FieldState.SUBMITTED.valueOf())) {
        return FieldState.SUBMITTED
    } else if (states.includes(FieldState.APPROVED.valueOf())) {
        return FieldState.APPROVED
    } else {
        return FieldState.INCOMPLETE
    }
}

const getAccredDocFields = (accred: AccreditationDocument | undefined, dateField: AccreditationRequiredField | undefined) => {
    switch(dateField) {
        case 'registrationDate': return [{ field: 'Date Registered', value: accred ? accred?.registrationDate : undefined }];
        case null: return [];
        case 'expiryDate': default: return [{ field: 'Expiry Date', value: accred ? accred.expiryDate : undefined }]
    }
}

const isExpired = (accred: AccreditationDocument | undefined, required_date: AccreditationRequiredField | undefined, renewalYears: number | undefined) => {
    if (accred===undefined) { return true }
    switch (required_date) {
        case 'registrationDate': {
            if(!accred.expiryDate) {
                return !accred.registrationDate || !renewalYears ? true : moment(accred.registrationDate, "DD-MM-YYYY").add(renewalYears, 'years').isBefore(moment())
            }
            return !accred.expiryDate ? true : moment(accred.expiryDate, "DD-MM-YYYY").isBefore(moment())
        }
        case null: return false;
        case 'expiryDate': default: {
            return !accred.expiryDate ? true : moment(accred.expiryDate, "DD-MM-YYYY").isBefore(moment())
        }
    }
}

// export const getSchoolState = (fields: SchoolInformation) => {
//     const match = Object.entries(fields).filter(el => {
//         if(el[0] === "employmentDeclarations") {
//             return null;
//         }
//         if(el[1] && el[1].state && el[1].state === 'rejected') {
//             return true;
//         }
//         return false;
//     })[0]
//     if(match) {
//         return 'rejected'
//     }

//     // At this point it is not rejected
//     // if there is any incomplete, then it is incomplete
//     // Then if there are any submitted, then submitted,
//     // Finally it should be approved

//     const isIncomplete = Object.entries(fields).filter(([key, value]) => {
//         if(key === "employmentDeclarations" || key === "preferredName") {
//             return null;
//         }
//         if(value && value.state && value.state === 'incomplete') {
//             return true;
//         }
//         return false;
//     })[0]
//     if(isIncomplete) {
//         return 'incomplete'
//     }

//     // If there are any fields that are missing, 

//     const isSubmitted = Object.entries(fields).filter(([key, value]) => {
//         if(key === "employmentDeclarations" || key === "preferredName") {
//             return null;
//         }
//         if(value && value.state && value.state === 'submitted') {
//             return true;
//         }
//         return false;
//     })[0]
//     if(isSubmitted) {
//         return 'submitted'
//     }

//     const isApproved = Object.entries(fields).filter(([key, value]) => {
//         if(key === "employmentDeclarations" || key === "preferredName") {
//             return null;
//         }
//         if(value && value.state && value.state === 'approved') {
//             return true;
//         }
//         return false;
//     })[0]
//     if(isApproved) {
//         return 'approved'
//     }

//     return 'incomplete'
// }
