// Apis not required user login
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import { get, getDatabase, ref, set as dbSet, query, equalTo, orderByChild, update } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, updateEmail, updatePassword, signOut } from "firebase/auth";
import {
  FirebaseBlogData,
  FirebaseGuidesData,
  Product,
  Products,
  ProfessionalLearningResource,
  ShopTerms,
  Terms,
  UpcomingTerms,
  Res,
  getEmploymentDeclaration,
  EmploymentDeclarationSubmission,
  EmploymentDeclaration,
  HowDidYouHearType,
  CandidateRefereeSurveyResponse,
  CandidateRefereeSurveyRequest,
  UserTerms
} from "./types";
import { app, auth } from "./configs";
import { ref as stRef, getStorage, deleteObject, uploadBytesResumable, TaskState, getDownloadURL } from "firebase/storage";
import { getUserStoragePath } from "./helpers";
import { setUserRegDefault, UserUpdate } from "../redux/userRegistrationSlice";
import { getIP } from "../helpers/getIp";
import { AccountType, TeacherEmploymentSetting, UserType } from "../redux/types";
import { Dispatch } from "redux";
import { setUserLogout } from "../redux/userSlice";
import { setSchoolRegDefault } from "../redux/schoolRegistrationSlice";
import { setSchoolDefault } from "../redux/schoolSlice";
import { FieldState } from "./types-teacher";

interface IStoreHelpDeskEnquiry {
  userId?: string;
  name: string;
  email: string;
  contactNumber: string;
  subject: string;
  issueDescription: string;
}

export const storeHelpDeskEnquiry = async ({
  userId,
  name,
  email,
  contactNumber,
  subject,
  issueDescription,
}: IStoreHelpDeskEnquiry) => {
  try {
    const timeStamp = moment().unix();
    const db = getDatabase();
    const id = uuidv4();

    await dbSet(ref(db, `helpDesk/${id}`), {
      userId: userId || null,
      contact: contactNumber,
      description: issueDescription,
      email: email,
      name,
      subject,
      timeStamp,
    });

    return true;
  } catch (error) {
    console.log("Errors happen when trying to store help desk enquiry.");
    console.log(error);
    return false;
  }
};

export const signInUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  try {
    const auth = getAuth(app);
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    return {
      uid: user.uid,
      emailIsVerified: user.emailVerified,
    };
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;

    console.log("Errors happen when tring to signin user.");
    console.log(error);

    return {
      errorCode: errorCode,
    };
  }
};

export const signOutUser = async (dispatch: Dispatch, successCallback?: () => void, errorCallback?: () => void) => {
  signOut(getAuth())
    .then(() => {
      successCallback && successCallback();
      sessionStorage.removeItem("spark-auth-token");
      dispatch(setUserLogout());
      dispatch(setUserRegDefault());
      dispatch(setSchoolRegDefault());
      dispatch(setSchoolDefault());
    })
    .catch((error) => {
      console.log(error);
      errorCallback && errorCallback();
    });
}

export const getTermsData = async () => {
  const db = getDatabase();
  const reference = ref(db, `termsV2`);

  return await get(reference).then((snapShot) => {
    return snapShot.toJSON() as Terms;
  });
};

export const getTermsDataNew = async () => {
  const db = getDatabase();
  const reference = ref(db, `terms`);
  return await get(reference).then((snapShot) => {
    return snapShot.toJSON() as UserTerms;
  });
};

export const getUpcomingTerms = async () => {
  const db = getDatabase();
  const reference = ref(db, `upcomingTermsV2`);

  return await get(reference).then((snapShot) => {
    return (snapShot.toJSON() ?? {}) as UpcomingTerms;
  });
};

export const getAllBlogs = async () => {
  const db = getDatabase();
  const reference = ref(db, `blog/published/`);
  const myQuery = query(reference, orderByChild('visibility'), equalTo(true));

  return await get(myQuery).then((snapShot) => {
    return snapShot.toJSON() as { [index: string]: FirebaseBlogData } | null;
  })
};

export const getBlogByBlogId = async (id: string) => {
  const db = getDatabase();
  const reference = ref(db, `blog/published/${id}`);

  return await get(reference).then((snapShot) => {
    return snapShot.toJSON() as FirebaseBlogData | null;
  });
};

export const getGuidesData = async (isTeacher: boolean) => {
  const db = getDatabase();
  const reference = ref(
    db,
    `publicDocuments/${isTeacher ? "teacherGuides" : "schoolGuides"}`
  );
  const myQuery = query(reference, orderByChild('visibility'), equalTo(true))

  return await get(myQuery).then((snapShot) => {
    console.log(snapShot.toJSON())
    return snapShot.toJSON() as FirebaseGuidesData | null;
  })
};

export const getProfessionalLearningResources = async () => {
  const db = getDatabase();
  const reference = ref(db, `resources`);

  try {
    const dataSnapshot = await get(reference);
    const professionalLearningResources = await dataSnapshot.toJSON();

    return (professionalLearningResources ?? {}) as {
      [resource: string]: ProfessionalLearningResource;
    };
  } catch (error) {
    console.log(
      "Error happens when trying to fetch professional learning resources of teachers."
    );
    console.log(error);
  }
};
// Updated to fetch resources from each respective state
export const getProfessionalLearningResourcesV2 = async (country: string, state: string) => {
  const db = getDatabase();
  const reference = ref(db, `countries/${country}/${state}/resources`);

  try {
    const dataSnapshot = await get(reference);
    const professionalLearningResources = dataSnapshot.toJSON();

    return (professionalLearningResources ?? {}) as {
      [resource: string]: ProfessionalLearningResource;
    };
  } catch (error) {
    console.log(
      "Error happens when trying to fetch professional learning resources of teachers."
    );
    console.log(error);
  }
};

export const getAllMerches = async () => {
  const db = getDatabase();
  const reference = ref(db, `/shop/products`);

  try {
    const dataSnapshot = await get(reference);
    const merches = await dataSnapshot.toJSON();

    return (merches ?? {}) as Products;
  } catch (error) {
    console.log("Errors happen when trying to get all merches.");
    console.log(error);
  }
};

export const getMerchById = async (id: string) => {
  const db = getDatabase();
  const reference = ref(db, `/shop/products/${id}`);

  try {
    const dataSnapshot = await get(reference);
    const merchandise = await dataSnapshot.toJSON();

    return merchandise as Product;
  } catch (error) {
    console.log(`Errors happen when trying to get merchandise with id ${id}.`);
    console.log(error);
  }
};

export const getStock = async (
  fbId: string,
  color: string,
  size: string
): Promise<
  Res<{
    color: string;
    size: string;
    fbId: string;
    stock: number;
    stripeId: string;
  }>
> => {
  try {
    const db = getDatabase();
    const reference = ref(
      db,
      `/shop/products/${fbId}/options/${color}/sizes/${size}`
    );
    const dataSnapshot = await get(reference);
    const sizeData = (await dataSnapshot.toJSON()) as {
      stripeId: string;
      stock: number;
    };

    return {
      code: 200,
      data: {
        stripeId: sizeData.stripeId,
        color,
        size,
        fbId,
        stock: (sizeData.stock ?? 0) as number,
      },
    };
  } catch (error) {
    console.log(
      `Fail to get the stock of product productId: ${fbId}, color: ${color}, size: ${size}`
    );
    console.log(error);
    return { code: 500 };
  }
};

export const getShopTerms = async () => {
  try {
    const db = getDatabase();
    const reference = ref(db, `/shop/terms`);
    const dataSnapshot = await get(reference);
    const shopTerms = (await dataSnapshot.toJSON()) as ShopTerms | null;
    if (!shopTerms) throw new Error("Shop terms data is empty");

    return { code: 200, value: shopTerms };
  } catch (error) {
    console.log("Errors happen when trying to load shop terms.");
    console.log(error);
    return { code: 500 };
  }
};

/* Teacher Application (Employment Declarations) APIs */

export const retrieveEmployentDeclaration = async (teacherId: string): Promise<Res<EmploymentDeclaration>> => {
  try {
    const dbRef = ref(getDatabase(), `employmentDeclarations/${teacherId}`);
    const snap = await get(dbRef);
    if (snap.exists()) {
      if (snap.val().dateResponded) return { code: 500 }
      return { data: getEmploymentDeclaration(snap), code: 200 };
    } else {
      return { code: 500 }
    }
  } catch (error) {
    console.log("Error happened whilst trying to retrieve teacher information");
    console.log(error);
    return { code: 500 }
  }
}

export const submitEmploymentDeclaration = async (teacherId: string, data: EmploymentDeclarationSubmission) => {
  try {
    const dbRef = ref(getDatabase(), `employmentDeclarations/${teacherId}`);
    update(dbRef, data);
    return { code: 200 };
  } catch (error) {
    console.log("Error happened whilst trying to submit this form.");
    console.log(error);
    return { code: 500 };
  }
}

// User Profile functions

export const removeProfilePicture = async (userId: string) => {
  try {
    const storageRef = stRef(getStorage(), getUserStoragePath(userId));
    deleteObject(storageRef);

    const userRef = ref(getDatabase(), `users/${userId}`);
    await update(userRef, {
      profilePictureURL: null
    });

    const teacherRef = ref(getDatabase(), `teachers/${userId}/profilePicture`);
    await update(teacherRef, {
      value: null
    });

  } catch (error) {
    console.log("Error happened whilst trying to remove your profile picture.");
    console.log(error);
    return { code: 500 };
  }
}

export const updateUserPhoto = async (uid: string, photoUrl: string) => {
  try {
    const docRef = ref(getDatabase(), `users/${uid}`);
    await update(docRef, {
      profilePictureURL: photoUrl
    })
    const teacherRef = ref(getDatabase(), `teachers/${uid}/profilePicture`);
    await update(teacherRef, {
      value: photoUrl
    });
    return { code: 200, data: "Update photo successfully." };
  } catch (error) {
    return { code: 500 };
  }
}

const metadatas = {
  pdf: { contentType: 'application/pdf' },
  xls: { contentType: 'application/vnd.ms-excel' },
  xlsx: { contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  doc: { contentType: 'application/msword' },
  docx: { contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  jpg: { contentType: 'image/jpeg' },
  jpeg: { contentType: 'image/jpeg' },
  png: { contentType: 'image/png' },
  csv: { contentType: 'text/csv' }, // Add appropriate MIME type for CSV
};

interface IuploadFileToStorageWithProgress { path: string, file: Blob, onProgress: (taskState: TaskState, progress: number) => void; }

export const uploadFileToStorageWithProgress = async ({ path, file, onProgress }: IuploadFileToStorageWithProgress): Promise<Res<string[]>> => {
  const storage = getStorage();
  return new Promise((resolve, reject) => {
    const storageRef = stRef(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on("state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(snapshot.state, progress);
      },
      (error) => {
        switch (error.code) {
          case "storage/unauthorized": reject({ error: 500, errorMsg: "User doesn't have permission to access the object when uploading general file", }); break;
          case "storage/canceled": reject({ error: 500, errorMsg: "User canceled the upload when uploading general file", }); break;
          case "storage/unknown": reject({ error: 500, errorMsg: "Unknown error occurred when uploading general file", }); break;
        }
      },
      () => { getDownloadURL(uploadTask.snapshot.ref).then((fileDownloadUrl) => { resolve({ code: 200, data: [uploadTask.snapshot.ref.name, fileDownloadUrl] }); }); }
    );
  });
};

export const isObjectEmpty = (obj?: Object | null): boolean => {
  return !obj ? true : Object.keys(obj).length === 0;
}

export const updateUserInfo = async (uid: string, updateData: UserUpdate, set?: boolean) => {
  try {
    let { user, teacher } = updateData;
    if (user && user.data && !isObjectEmpty(user.data)) {
      const docRef = ref(getDatabase(), `users/${uid}${user.path ? user.path : ''}`);
      await update(docRef, user.data)
    }
    if (teacher && teacher.data && !isObjectEmpty(teacher.data)) {
      const teacherRef = ref(getDatabase(), `teachers/${uid}${teacher.path ? teacher.path : ''}`);
      if (set) {
        await dbSet(teacherRef, teacher.data);
      } else {
        await update(teacherRef, teacher.data);
      }

      const updateRef = ref(getDatabase(), `teachers/${uid}/accountState`)
      await update(updateRef, {
        firstTime: false
      })
    }
    return { code: 200 }
  } catch (error: any) {
    return { code: 500, error: error }
  }
}

export const updateUserEmail = async (email: string, password: string, newEmail: string, emailOnly?: boolean) => {
  try {
    if (!auth.currentUser) { throw new Error("Not authenticated!") };
    const res = await signInUserWithEmailAndPassword(email, password);
    if (res.errorCode) {
      return { code: 500, errorMsg: res.errorCode }
    }
    await updateEmail(auth.currentUser, newEmail);

    if (emailOnly) { return { code: 200 } };

    // Update documents
    let updateData: UserUpdate = {
      user: { path: '/', data: { email: newEmail } },
      teacher: { path: '/email', data: { value: newEmail } }
    }
    await updateUserInfo(auth.currentUser.uid, updateData);
    return { code: 200 }
  } catch (error: any) {
    console.log('There was an error updating user email');

    return { code: 500, errorMsg: error.code }
  }
}

export const updateUserPassword = async (email: string, password: string, newPassword: string) => {
  try {
    if (!auth.currentUser) { throw new Error("Not authenticated!") };
    const res = await signInUserWithEmailAndPassword(email, password);
    if (res.errorCode) {
      return { code: 500, errorMsg: res.errorCode }
    }
    await updatePassword(auth.currentUser, newPassword);
    return { code: 200 }
  } catch (error: any) {
    console.log('There was an error updating user password');
    return { code: 500, errorMsg: error.code }
  }
}

export const removeStorageFile = async (path: string) => {
  const storage = getStorage();
  const objRef = stRef(storage, path);
  await deleteObject(objRef);
}

export const submitHowDidYouHear = async (userId: string, hdyh: HowDidYouHearType) => {
  try {
    const temp: HowDidYouHearType = {
      ...hdyh
    };
    const userNode = ref(getDatabase(), `teachers/${userId}/howDidYouHear`);

    const data = await get(userNode);
    if (!data.val() || !data.val().firstRequest) {
      temp.firstRequest = temp.lastRequest;
    }

    console.log('Sending Request...');
    console.log(temp, userId);

    await update(userNode, temp);
    return { code: 200 }
  } catch (error) {
    return { code: 500, error: error }
  }
}

export const submitRefereeSurvey = async (refereeId: string, survey: CandidateRefereeSurveyResponse) => {
  try {
    const refereeNode = ref(getDatabase(), `referenceReview/${refereeId}/response`);
    const dateToday = moment.tz(moment(), "Australia/Melbourne");
    await update(refereeNode, {
      ...survey,
      ip: await getIP(),
      timeResponded: dateToday.unix()
    });
    return { code: 200 }
  } catch (error: any) {
    console.log(`${error.message}`);
    return { code: 500, error: error }
  }

  // try {
  //   // Delete reference request node after submission
  //   const referenceNode = ref(getDatabase(), `referenceReview/${refereeId}`);
  //   await remove(referenceNode);

  //   return {code: 200}
  // } catch (error) {
  //   return {code: 500, error: error}
  // }
}

export const checkRefereeValidity = async (refereeId: string): Promise<Res<CandidateRefereeSurveyRequest | undefined>> => {
  try {
    const refReviewNode = ref(getDatabase(), `referenceReview/${refereeId}`);
    const snap = await get(refReviewNode);
    if (snap.exists()) {
      return { code: 200, data: snap.val() as CandidateRefereeSurveyRequest };
    }

    return { code: 200, data: undefined }
  } catch (error) {
    return { code: 500, error: error }
  }
}

export const updateUserTimestamp = async (userId: string) => {
  try {
    const userRef = ref(getDatabase(), `users/${userId}`);
    await update(userRef, {
      lastVerified: moment.tz(moment(), "Australia/Melbourne").unix()
    });
  } catch (error: any) {
    return { code: 500, error: error }
  }
}

export const fetchDocLink = async (docSlug: string): Promise<Res<string | null>> => {
  try {
    const docRef = ref(getDatabase(), `links/${docSlug}`)
    const snap = await get(docRef);
    if (snap.exists()) {
      return { code: 200, data: snap.val().url }
    } else {
      return { code: 200, data: null }
    }
  } catch (error: any) {
    return { code: 500, error: error }
  }
}

export const selectUserAccountType = async (userId: string, accountType: AccountType, locationState: string, workType?: TeacherEmploymentSetting, user?: UserType|null) => {
  try {
    if (accountType === 'teacher' && !workType) { throw new Error('Invalid working type. Please try again.') };
    let userRef = ref(getDatabase(), `users/${userId}`);
    await update(userRef, {
      accountType: accountType
    });

    if (accountType === 'teacher') {
      let teacherRef = ref(getDatabase(), `teachers/${userId}`);
      await update(teacherRef, {
        accountState: { firstTime: true },
        employmentSetting: { [`${uuidv4()}`]: { locationState: locationState, type: workType } },
        ...(user ? {
          ...(user.firstName ? {firstName: {value: user.firstName, state: FieldState.SUBMITTED}} : null),
          ...(user.preferredName ? {firstNameActual: {value: user.firstName, state: FieldState.SUBMITTED}} : null),
          ...(user.lastName ? {lastName: {value: user.lastName, state: FieldState.SUBMITTED}} : null),
          ...(user.email ? {email: {value: user.email, state: FieldState.SUBMITTED}} : null),
        } : null)
      })
    }

    return { code: 200 }

  } catch (error: any) {
    return { code: 500, error: error }
  }
}