import { useDispatch, useSelector } from "react-redux";
import { EmployeeDocsModalWrapper, RegModalWrapper } from "../ModalContent";
import { RootState } from "../../../../redux/store";
import pxToRem from "../../../../helpers/pxToRem";
import { Stack } from "@mui/material";
import styles from "../index.module.scss";
import { useEffect, useMemo } from "react";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { getIncompleteState } from "../../ReviewPage/helper";
import { FieldState, TeacherAccountState } from "../../../../firebase/types-teacher";
import { LightTooltip } from "../../../../components/Tooltip";
import { getAccountNumberError, getErrorMsgForTextInput } from "../../../../helpers/inputValidators";
import useTextInput from "../../../../hooks/useTextInput";
import InputMask from "react-input-mask";
import { UserRegTextField } from "../../../../components/TextField";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';


const BankDetails = () => {
    const dispatch = useDispatch();
    const reg = useSelector((state: RootState) => state.userReg.fields);

    const accountName = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Account Name") }, defaultValue: reg.bank?.accountName });
    const bsb = useTextInput({ inputValidator: (input: string) => { return input.length > 0 && input.length < 7 ? "BSB should be 6 digits" : getErrorMsgForTextInput(input, "BSB") }, defaultValue: reg.bank?.bsb });
    const accountNumber = useTextInput({ inputValidator: (input: string) => { return getAccountNumberError(input) }, defaultValue: reg.bank?.accountNumber });

    const accountState = useSelector((state: RootState) => state.userReg.fields.accountState?.value);

    const handleUpdate = () => {
        let updateData: UserUpdate = {};

        if (isAllComplete &&
            (
                accountName.value !== reg.bank?.accountName ||
                accountNumber.value !== reg.bank.accountNumber ||
                bsb.value !== reg.bank.bsb
            )
        ) {
            updateData = {
                teacher: {
                    path: `/bank`,
                    data: {
                        [`details/${uuidv4()}`]: {
                            accountName: accountName.value,
                            accountNumber: accountNumber.value.trimEnd(),
                            bsb: bsb.value,
                            timeSubmitted: moment.tz(moment(), "Australia/Melbourne").unix()
                        },
                        state: FieldState.SUBMITTED
                    }
                }
            }
            dispatch(updateFields(updateData));
        }
    }

    useEffect(() => {
        reg.bank?.accountName && accountName.setValue(reg.bank?.accountName);
        reg.bank?.bsb && bsb.setValue(reg.bank?.bsb);
        reg.bank?.accountNumber && accountNumber.setValue(reg.bank?.accountNumber);
    }, [reg.bank]);

    const isAllComplete = useMemo(() => {
        return accountName.value.trim() !== '' && !accountName.hasError && bsb.value.trim().length === 7 && !bsb.hasError && accountNumber.value.trim() !== '' && !accountNumber.hasError
    }, [accountName, bsb, accountNumber])

    return (
        <>
            {
                accountState !== TeacherAccountState.INCOMPLETE.valueOf() && !reg.bank?.value ?
                    <EmployeeDocsModalWrapper title={"Employee Documents - Bank Details"} handleUpdate={handleUpdate} disableBtns isAllComplete={isAllComplete}>
                        <Stack gap={pxToRem(24)} className={styles.modalContainer}>
                            <Stack className={styles.titleContainer}>
                                <h5 className={styles.h5} >Bank Details</h5>
                                {
                                    reg.bank?.state === FieldState.INCOMPLETE ?
                                        <Stack className={styles.infoBox}>
                                            <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8" /> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white" /> </svg>
                                            <small>To ensure that you are always paid on time, you will need to provide us with your bank details before you start working.<br /><br />Please download the Spark Relief Teacher Bank Details template below, complete it and upload it.</small>
                                        </Stack>
                                        :
                                        <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                                            <LightTooltip placement="bottom-start" title={<p className={styles.p}>To ensure that you are always paid on time, you will need to provide us with your bank details before you start working.<br /><br />Please download the Spark Relief Teacher Bank Details template below, complete it and upload it.</p>}>
                                                <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8" /> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white" /> </svg>
                                            </LightTooltip>
                                            <p className={styles.p}>Why we need this information</p>
                                        </Stack>
                                }
                            </Stack>
                            <UserRegTextField
                                value={accountName.value} onChange={(e) => accountName.setValue(e.target.value)}
                                error={accountName.hasTouched && accountName.hasError} helperText={accountName.getErrorMessage()}
                                label="Account Name"
                            />
                            <InputMask
                                mask={'999-999'}
                                maskPlaceholder={''}
                                value={bsb.value}
                                onChange={(e) => bsb.setValue(e.target.value)}
                                disabled={false}
                            >
                                <UserRegTextField
                                    error={bsb.hasTouched && bsb.hasError} helperText={bsb.getErrorMessage()}
                                    label="BSB"
                                />
                            </InputMask>
                            <InputMask
                                mask={'999 999 9999'}
                                maskPlaceholder={''}
                                value={accountNumber.value}
                                onChange={(e) => accountNumber.setValue(e.target.value)}
                                disabled={false}
                            >
                                <UserRegTextField
                                    // value={accountNumber.value} onChange={(e) => { if (e.target.value.length < 10) { accountNumber.setValue(e.target.value) } }}
                                    error={accountNumber.hasTouched && accountNumber.hasError} helperText={accountNumber.getErrorMessage()}
                                    label="Account Number"
                                />
                            </InputMask>
                        </Stack>
                    </EmployeeDocsModalWrapper>
                    :
                    <RegModalWrapper title="Employee Documents - Bank Details" handleUpdate={handleUpdate}
                        state={getIncompleteState({
                            bank: reg.bank,
                        })} isAllComplete={isAllComplete} disableBtns
                    >
                        <Stack gap={pxToRem(24)} className={styles.modalContainer}>
                            <Stack className={styles.titleContainer}>
                                <h5 className={styles.h5} >Bank Details</h5>
                                {
                                    reg.bank?.state === FieldState.INCOMPLETE ?
                                        <Stack className={styles.infoBox}>
                                            <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8" /> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white" /> </svg>
                                            <small>To ensure that you are always paid on time, you will need to provide us with your bank details before you start working.</small>
                                        </Stack>
                                        :
                                        <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                                            <LightTooltip placement="bottom-start" title={<p className={styles.p}>To ensure that you are always paid on time, you will need to provide us with your bank details before you start working.</p>}>
                                                <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8" /> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white" /> </svg>
                                            </LightTooltip>
                                            <p className={styles.p}>Why we need this information</p>
                                        </Stack>
                                }
                            </Stack>
                            <UserRegTextField
                                value={accountName.value} onChange={(e) => accountName.setValue(e.target.value)}
                                error={accountName.hasTouched && accountName.hasError} helperText={accountName.getErrorMessage()}
                                label="Account Name"
                            />
                            <InputMask
                                mask={'999-999'}
                                maskPlaceholder={''}
                                value={bsb.value}
                                onChange={(e) => bsb.setValue(e.target.value)}
                                disabled={false}
                            >
                                <UserRegTextField
                                    error={bsb.hasTouched && bsb.hasError} helperText={bsb.getErrorMessage()}
                                    label="BSB"
                                />
                            </InputMask>
                            <InputMask
                                mask={'999 999 9999'}
                                maskPlaceholder={''}
                                value={accountNumber.value}
                                onChange={(e) => accountNumber.setValue(e.target.value)}
                                disabled={false}
                            >
                                <UserRegTextField
                                    // value={accountNumber.value} onChange={(e) => { if (e.target.value.length < 10) { accountNumber.setValue(e.target.value) } }}
                                    error={accountNumber.hasTouched && accountNumber.hasError} helperText={accountNumber.getErrorMessage()}
                                    label="Account Number"
                                />
                            </InputMask>
                        </Stack>
                    </RegModalWrapper>
            }
        </>
    )
};

export default BankDetails;