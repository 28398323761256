import { IconButton, Stack, Zoom } from "@mui/material";
import { MultiFileType } from ".";
import pxToRem from "../../helpers/pxToRem";
import styles from "../../pages/Dashboard/RegistrationModals/index.module.scss";
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactNode } from "react";


interface IRegMultipleFiles {
    files?: MultiFileType[];
    removeFile: (id: string) => void;
}
export const RegMultipleFiles = ({ files, removeFile }: IRegMultipleFiles) => {
    if (!files || files.length === 0) return null;
    return (
        <Stack gap={pxToRem(12)}>
            {
                files.map((el, i) => (
                    <Stack key={`empDocs--${el.id}`} className={styles.uploadSuccess}>
                        <small><a
                            href={typeof el.file === 'string' ? el.file : URL.createObjectURL(el.file)}
                            target="_blank" //rel="noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit' }}>{el.fileName || 'Document Uploaded'}</a></small>
                        <IconButton onClick={() => { removeFile(el.id) }}><svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.477 24.6086H20.532C21.8419 24.6086 22.6069 23.9106 22.6642 22.6102L23.2666 8.79348H24.5862C25.0738 8.79348 25.4467 8.41101 25.4467 7.93293C25.4467 7.45484 25.0642 7.09149 24.5862 7.09149H20.4364V5.65723C20.4364 4.12736 19.4515 3.21899 17.8069 3.21899H14.1734C12.5288 3.21899 11.544 4.12736 11.544 5.65723V7.09149H7.41329C6.9352 7.09149 6.55273 7.4644 6.55273 7.93293C6.55273 8.42057 6.9352 8.79348 7.41329 8.79348H8.74237L9.35432 22.6102C9.40213 23.9106 10.1671 24.6086 11.477 24.6086ZM13.5328 5.74329C13.5328 5.31301 13.8292 5.03572 14.3073 5.03572H17.6826C18.1607 5.03572 18.4571 5.31301 18.4571 5.74329V7.09149H13.5328V5.74329ZM12.9304 21.6731C12.5479 21.6731 12.2802 21.4245 12.2706 21.0421L11.9838 10.8875C11.9742 10.505 12.242 10.2469 12.6436 10.2469C13.026 10.2469 13.2938 10.4955 13.3033 10.8779L13.5902 21.0325C13.6093 21.415 13.3416 21.6731 12.9304 21.6731ZM15.9997 21.6731C15.5981 21.6731 15.3304 21.4245 15.3304 21.0421V10.8779C15.3304 10.505 15.5981 10.2469 15.9997 10.2469C16.4013 10.2469 16.6786 10.505 16.6786 10.8779V21.0421C16.6786 21.4245 16.4013 21.6731 15.9997 21.6731ZM19.069 21.6827C18.6579 21.6827 18.3902 21.415 18.4093 21.0421L18.6961 10.8779C18.7057 10.4955 18.9734 10.2469 19.3559 10.2469C19.7575 10.2469 20.0252 10.505 20.0156 10.8875L19.7288 21.0421C19.7192 21.4245 19.4515 21.6827 19.069 21.6827Z" fill="#555555" /> </svg> </IconButton>
                    </Stack>
                ))
            }
        </Stack>
    )
}

interface IRegFileUploadLabel {
    text?: ReactNode;
    file?: any;
    reset?: () => void;
}
export const RegFileUploadLabel = ({ text, file, reset }: IRegFileUploadLabel) => {
    return (
        <Stack direction={'row'} gap={pxToRem(12)} alignItems={'center'}>
            {text ? text : <small className={styles.fileUploadLabel}>Upload Document</small>}
            {
                reset ?
                    <Zoom in={file !== undefined}>
                        <IconButton onClick={reset}>
                            {/* <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="18" cy="18" r="18" fill="#EC5B53" /> <path d="M13.4773 28.6083H22.5322C23.8422 28.6083 24.6071 27.9103 24.6645 26.6099L25.2669 12.7932H26.5864C27.074 12.7932 27.447 12.4108 27.447 11.9327C27.447 11.4546 27.0645 11.0912 26.5864 11.0912H22.4366V9.65699C22.4366 8.12711 21.4517 7.21875 19.8071 7.21875H16.1737C14.5291 7.21875 13.5442 8.12711 13.5442 9.65699V11.0912H9.41353C8.93545 11.0912 8.55298 11.4642 8.55298 11.9327C8.55298 12.4203 8.93545 12.7932 9.41353 12.7932H10.7426L11.3546 26.6099C11.4024 27.9103 12.1673 28.6083 13.4773 28.6083ZM15.533 9.74305C15.533 9.31277 15.8295 9.03548 16.3075 9.03548H19.6828C20.1609 9.03548 20.4573 9.31277 20.4573 9.74305V11.0912H15.533V9.74305ZM14.9307 25.6729C14.5482 25.6729 14.2805 25.4243 14.2709 25.0418L13.984 14.8873C13.9745 14.5048 14.2422 14.2466 14.6438 14.2466C15.0263 14.2466 15.294 14.4952 15.3036 14.8777L15.5904 25.0322C15.6095 25.4147 15.3418 25.6729 14.9307 25.6729ZM18 25.6729C17.5984 25.6729 17.3306 25.4243 17.3306 25.0418V14.8777C17.3306 14.5048 17.5984 14.2466 18 14.2466C18.4016 14.2466 18.6788 14.5048 18.6788 14.8777V25.0418C18.6788 25.4243 18.4016 25.6729 18 25.6729ZM21.0693 25.6824C20.6581 25.6824 20.3904 25.4147 20.4095 25.0418L20.6964 14.8777C20.7059 14.4952 20.9737 14.2466 21.3561 14.2466C21.7577 14.2466 22.0255 14.5048 22.0159 14.8873L21.729 25.0418C21.7195 25.4243 21.4517 25.6824 21.0693 25.6824Z" fill="white" /> </svg> */}
                            <DeleteIcon />
                        </IconButton>
                    </Zoom>
                    : null
            }
        </Stack>
    )
}